import resources from '../../resources.json';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import httpService from "../../services/httpService";
import { UserInfo } from "../../custom";
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import './../confirmation-error/UnavailablePage.css'

type PageProps = {
    userInfo?: UserInfo | null,
    authContext?: AuthState | null,
};

const UnavailablePage = ({ userInfo, authContext }: PageProps) => {

    return (
        <div>

            {
                <div className="container">
                    <div>
                        <div>
                            <p className='title'>{resources.errorText.unavailablePageTitle}</p>
                            <p>{resources.errorText.unavailablePageSubTitle}</p>
                        </div>
                        <div className="contentPanel">
                            <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <svg width="25" height="22" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8827 1.30997C11.0503 -0.103484 8.94972 -0.103485 8.11728 1.30997L0.28891 14.6021C-0.537147 16.0047 0.506664 17.7499 2.17163 17.7499H17.8284C19.4933 17.7499 20.5371 16.0047 19.7111 14.6021L11.8827 1.30997ZM11.25 5.87488H8.75L9.0625 11.4999H10.9375L11.25 5.87488ZM10.9375 12.7499H9.0625V14.6249H10.9375V12.7499Z" fill="#d82216" /></svg>
                                <div className='messageDetails'>
                                    {resources.errorText.unavailablePageMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default UnavailablePage;
