import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../home/Home';
import { AuthContext } from '../../contexts/AuthContext';
import { LoginCallback } from '@okta/okta-react';
import RequiredAuth from './RequiredAuth';
import Loading from './Loading';
import Error from './Error';
import NotFound from '../notFound/NotFound';
import Layout from '../shared/Layout';
import ScrollToTop from './ScrollToTop';
import RequestForm from '../requestForm/RequestForm';
import RequestApprove from '../resolveRequest/RequestApprove';
import RequestDecline from '../resolveRequest/RequestDecline';
import SuccessConfirmation from '../confirmation-error/SuccessConfirmation';
import ErrorPage from '../confirmation-error/ErrorPage';
import UnauthorisedPage from '../confirmation-error/UnauthorisedPage';
import UnavailablePage from '../confirmation-error/UnavailablePage';
import FrequentlyAskedQuestions from '../frequentlyAskedQuestions/FrequentlyAskedQuestions';
import History from '../history/History';
import FAQForm from '../frequentlyAskedQuestions/FAQForm';
import FAQEditForm from '../frequentlyAskedQuestions/FAQEditForm';
import Templates from '../templates/Templex';
import AcknowledgementsTable from '../analytics/AcknowledgementTable';
import FAQ from '../frequentlyAskedQuestions/FAQ';
import RequestReminder from '../resolveRequest/RequestReminder';

function RouteHandler() {
    return (
        <AuthContext.Consumer>
            {(authContext) => {
                return (
                    <Layout>
                        <ScrollToTop />
                        <Routes>
                           <Route path="*" element={<UnavailablePage />} />
                            <Route path="/implicit/callback" element={<LoginCallback errorComponent={Error}  loadingElement={<Loading />} />} />
                            
                            <Route path="/" element={<RequiredAuth />}>
                                <Route index element={<Home userInfo={authContext?.userInfo} />} />
                            </Route>
                            <Route path="/approve/:token?" element={<RequiredAuth />}>
                                <Route index element={<RequestApprove userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/Requests/CloseRequest/:requestID?" element={<RequiredAuth />}>
                                <Route index element={<RequestReminder type="Close" userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/Requests/SaveReminder/:requestID?" element={<RequiredAuth />}>
                                <Route index element={<RequestReminder type="NoResponse" userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/decline/:token?" element={<RequiredAuth />}>
                                <Route index element={<RequestDecline userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/success" element={<RequiredAuth />}>
                                <Route index element={<SuccessConfirmation userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/error" element={<RequiredAuth />}>
                                <Route index element={<ErrorPage userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/unavailable" element={<RequiredAuth />}>
                                <Route index element={<UnavailablePage userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/unauthorised" element={<RequiredAuth />}>
                                <Route index element={<UnauthorisedPage userInfo={authContext?.userInfo} authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="/Request" element={<RequiredAuth />}>
                                <Route index element={<RequestForm userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path='/request/:requestID?' element={<RequiredAuth />}>
                                <Route index element={<RequestForm userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path="/templates" element={<RequiredAuth />}>
                                <Route index element={<Templates userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path="/frequentlyAskedQuestions" element={<RequiredAuth />}>
                                <Route index element={<FrequentlyAskedQuestions userInfo={authContext?.userInfo} />} />
                            </Route>
                            <Route path="/viewFrequentlyAskedQuestions" element={<RequiredAuth />}>
                                <Route index element={<FAQ authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path="/FAQForm" element={<RequiredAuth />}>
                                <Route index element={<FAQForm userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path='/FAQForm' element={<RequiredAuth />}>
                                <Route index element={<FAQForm userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path='/FAQEditForm/:ID' element={<RequiredAuth />}>
                                <Route index element={<FAQEditForm userInfo={authContext?.userInfo} authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path='/viewAllAcknowledgments' element={<RequiredAuth />}>
                                <Route index element={<AcknowledgementsTable authContext={authContext?.authState} user={authContext?.user} />} />
                            </Route>
                            <Route path='/request/:requestID?/history' element={<RequiredAuth />}>
                                <Route index element={<History authContext={authContext?.authState} />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Layout>
                );
            }}
        </AuthContext.Consumer>
    );
}

export default RouteHandler;