import resources from '../../resources.json';
import { useState, useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Request } from "../../Models/Model";
import './RequestForm.css';
import { browserName } from 'react-device-detect';
import { AcknowledgementManual } from "../../Models/Model";
import Acknowledgement from "../Acknowledgement/Acknowledgement";
import httpService from "../../services/httpService";
import { UserInfo } from "../../custom";
import { AuthState } from '../../custom';
import { Template, Watcher } from '../../Models/Model';
import Loading from '../routeHandler/Loading';
import Watchers from '../watchers/Watchers';
import { User } from '../../Models/User';
import { Modal } from 'react-bootstrap';
import BCGDatePicker from "@bcg-web/bcg-react-datepicker";
import "@bcg-web/bcg-react-datepicker/dist/bcg-react-datepicker.css";
import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import moment from "moment-timezone";
import React from 'react';
import useTemplates from "./useTemplates";
import useErrorPages from "./useErrorPages";
import { Container } from '../../Models/Container';

type PageProps = {
    userInfo?: UserInfo | null,
    authContext?: AuthState | null
    user?: User | null
};

const RequestForm = ({ userInfo, authContext, user }: PageProps) => {

    const params = useParams();
    const navigate = useNavigate();
    const { templates } = useTemplates(authContext, handleTemplateLoading);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAcknowledge, setIsOpenAcknowledge] = useState(false);
    const [requestID, setRequestID] = useState('');
    const [request, setRequest] = useState<Request>(Object);
    const [requestType, setRequestType] = useState('');
    const [declineReason, setDeclineReason] = useState('');
    const [AcknowledgeToken, setToken] = useState('');
    const [isDeclineEnabled, setIsDeclineEnabled] = useState(true);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [activeTemplate, setActiveTemplate] = useState<Template>();
    const [namesArray, setNamesArray] = useState<User[]>([]);
    const [partnerEmail, setPartnerEmail] = useState<User>();
    const [watchers, setWatchers] = useState<Watcher[]>([]);
    const [message, setMessage] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalDialog, SetIsModalDialog] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [allowChanges, setAllowChanges] = useState(true);
    const [adminPending, setAdminPending] = useState(false);
    const [decline, setDecline] = useState(false);
    const [refreshData, setRefresh] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [disableAllButtons, setDisableAllButtons] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [isFirstName, setFirstName] = useState(false);
    const [isLastName, setLastName] = useState(false);
    const [isEMail, setEMail] = useState(false);
    const [isCompanyName, setCompanyName] = useState(false);
    const [isDateControl, setDateControl] = useState(false);
    const [isOffline, setOffline] = useState(false);
    const [isOfflineEdit, setOfflineEdit] = useState(false);
    const [ackgridData, setACKGridData] = useState<any[]>([]);
    const [fileName, setFileName] = useState('');
    const [displayDownload, setDisplay] = useState(false);
    const history = '/nrl/request/' + params.requestID + '/history';
    const [acknowledgementCSV, setAcknowledgmentCSV] = useState('');
    const headers = [
        { label: "FirstName", key: "FirstNames" },
        { label: "LastName", key: "LastNames" },
        { label: "Email", key: "EMail" },
        { label: "Company", key: "Company" },
        { label: "AcknowledgedDate(UTC)", key: "LastAcknowledged" }
    ];

    //FORM CONFIGURATION
    const methods = useForm<Request>({
        shouldUseNativeValidation: true, shouldFocusError: false, mode: "onChange"
    });
    const methodss = useForm<AcknowledgementManual>({
        shouldUseNativeValidation: true, shouldFocusError: false, mode: "onChange"
    });
    const { control, register } = useForm<AcknowledgementManual>({
        shouldUseNativeValidation: true,
        shouldFocusError: false
    });
    const { errors } = methods.formState;
    const isFormInvalid = Object.keys(errors).length > 0;

    const { navigateToErrorPage } = useErrorPages()

    function handleTemplateLoading(value: boolean) {
        setIsLoading(isLoading || value);
        setIsFetching(isFetching || value);
    }

    const [messages, setMessages] = useState<Container[]>();

    useEffect(() => {
        (async () => {
            if (params.requestID) {
                setRequestID(params.requestID);
                setIsEditing(true);
                await httpService.getRequestData(params.requestID!, authContext?.accessToken)
                    .then(response => {
                        setRequest(response.data);
                    }).catch(err => {
                        navigateToErrorPage(err);
                    });
            } else if (user?.Role === 'COORDINATOR') {
                httpService.getPartners(authContext?.accessToken).then(response => {
                    setNamesArray(response.data);
                    var email = response.data[0];
                    setPartnerEmail(email);
                }).catch(err => navigateToErrorPage(err));
            }

            await httpService.getMessages('INTERNALREQUEST', authContext)
                .then(response => {
                    setMessages(response.data);
                })
                .catch(err => {
                });

            await httpService.getAcknowledgementsCSV(params.requestID!, authContext?.accessToken)
                .then(response => {
                    setAcknowledgmentCSV("data:text/csv;base64," + response.data.Content);
                })
                .catch(err => {
                    navigateToErrorPage(err)
                });

            await httpService.getAcknowledgementsForDownload(params.requestID!, authContext?.accessToken)
                .then(response => {
                    handleAckData(response.data);
                })
                .then(p => setIsLoading(false))
                .then(p => setIsFetching(false))
                .catch(err => {
                    navigateToErrorPage(err)
                });
        })();
        return () => {

            // this now gets called when the component unmounts
        };
    }, []);

    useEffect(() => {
        (async () => {

            if (((document.getElementById("FirstName")) as HTMLInputElement) !== null) {
                ((document.getElementById("FirstName")) as HTMLInputElement).value = "";
            }
            if (((document.getElementById("LastName")) as HTMLInputElement)) {
                ((document.getElementById("LastName")) as HTMLInputElement).value = "";
            }
            if (((document.getElementById("EMailManual")) as HTMLInputElement)) {
                ((document.getElementById("EMailManual")) as HTMLInputElement).value = "";
            }
            if (((document.getElementById("CompanyManual")) as HTMLInputElement)) {
                ((document.getElementById("CompanyManual")) as HTMLInputElement).value = "";
            }
            if (((document.getElementById("ManualReason")) as HTMLInputElement)) {
                ((document.getElementById("ManualReason")) as HTMLInputElement).value = "";
            }
            if (((document.getElementById("ManualDescription")) as HTMLInputElement)) {
                ((document.getElementById("ManualDescription")) as HTMLInputElement).value = "";
            }
            setStartDate(undefined);
        })();

        return () => {

            // this now gets called when the component unmounts
        };
    }, [isModalDialog]);

    useEffect(() => {
        (async () => {

            if (requestType === 'acknowledge') {

                await methodss.handleSubmit(onSubmitManual)();
            }
            if (requestType !== '') {
                await methods.handleSubmit(onSubmit)();
            }
        })();

        return () => {

            // this now gets called when the component unmounts
        };
    }, [requestType]);

    useEffect(() => {
        (async () => {
            if (decline) {
                setRequestType("decline");
            }
        })();

        return () => {

            // this now gets called when the component unmounts
        };
    }, [decline]);
    useEffect(() => {
        (async () => {

        })();

        return () => {

            // this now gets called when the component unmounts
        };
    }, []);

    const declineMe = async () => {
        console.log(declineReason);
        await httpService.declineRequest(params.requestID!, authContext?.accessToken, declineReason).then(() => {
            navigate("/", { state: { status: "declined" } });
        }).catch(err => {
            navigateToErrorPage(err)
        });

    }
    const closeMe = async () => {
        console.log(declineReason);
        await httpService.closeRequest(params.requestID!, authContext?.accessToken, declineReason).then(() => {
            navigate("/", { state: { status: "closed" } });
        }).catch(err => {
            navigateToErrorPage(err)
        });

    }
    const onInputCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.checked) {
            setOffline(true);
        } else {
            setOffline(false);
        }

    }

    const onSubmitManual = async (data: AcknowledgementManual) => {


    }
    const onSubmit = async (data: Request) => {
        if (!isEditing) {
            setDisableAllButtons(true);
            let partnerName: string = `${userInfo?.given_name} ${userInfo?.family_name}`;
            let partnerID: string = "";
            let partnerEmail: string = "";
            if (user?.Role === 'COORDINATOR') {
                if (request.Status === undefined) {
                    if (((document.getElementById("PartnerName")) as HTMLSelectElement).value !== "Select") {
                        partnerID = ((document.getElementById("PartnerName")) as HTMLSelectElement).value;
                        partnerName = ((document.getElementById("PartnerName")) as HTMLSelectElement).selectedOptions[0].text;
                        partnerEmail = ((document.getElementById("PartnerEmail")) as HTMLSelectElement).selectedOptions[0].text;
                    }
                }
            }

            const newRequest: Request = {
                Status: ((document.getElementById("isOffline")) as HTMLInputElement).checked ? "OPEN" : "NEW",
                Project: { Transaction: data.Project.Transaction, Code: data.Project.Code },
                Partner: {
                    Role: "PARTNER",
                    EMail: user?.Role === 'COORDINATOR' ? partnerEmail : userInfo?.email ? userInfo.email : "",
                    Name: partnerName,
                    ID: user?.Role === 'COORDINATOR' ? Number(partnerID) : 0
                },
                Coordinator: {
                    FirstNames: data.Coordinator.FirstNames,
                    LastNames: data.Coordinator.LastNames,
                    EMail: data.Coordinator.EMail,
                    Company: data.Coordinator.Company
                },
                Template: { TemplateID: data.Template.TemplateID },
                IsAccepted: user?.Role === "COORDINATOR" ? false : data.IsAccepted,
                Watchers: data.Watchers,
                Role: user?.Role,
                Offline: ((document.getElementById("isOffline")) as HTMLInputElement).checked == true ? true : false,
            }
            await httpService.createRequest(newRequest, authContext?.accessToken).then((response) => {
                navigate("/", user?.Role === "COORDINATOR" && ((document.getElementById("isOffline")) as HTMLInputElement).checked == false ? {
                    state: {
                        status: "pending",
                        requestID: response.Content,
                        new: ""
                    }
                } : { state: { status: "open", requestID: response.Content, new: "NEWREQUEST" } });
            }).catch(err => {
                navigateToErrorPage(err)
            });
        } else {
            if (requestType !== '') {
                setDisableAllButtons(true);
                if (requestType !== "decline") {

                    let partnerName: string = `${userInfo?.given_name} ${userInfo?.family_name}`;
                    let Email: string = userInfo?.email ? userInfo.email : "";
                    let partnerID: string = "";
                    let tempData: string = "";

                    console.log((document.getElementById("PartnerName")) as HTMLSelectElement);
                    if (request.Status === 'FAILED') {
                        if (((document.getElementById("PartnerName")) as HTMLSelectElement).value !== "Select") {

                            let e = (document.getElementById("PartnerName")) as HTMLSelectElement;
                            let sel = e.selectedIndex;
                            let opt = e.options[sel];
                            partnerID = opt.value;
                            partnerName = opt.text;

                            let email = (document.getElementById("PartnerEmail")) as HTMLSelectElement;
                            let selEmail = email.selectedIndex;
                            let optEmail = email.options[selEmail];
                            Email = optEmail.text;

                        }
                    }
                    let tempID = (document.getElementById("TemplateID")) as HTMLSelectElement;

                    tempData = tempID.options[tempID.selectedIndex].value;

                    console.log(data);
                    console.log("CASE CODE: " + data.Project.Code);
                    console.log("REASON: " + data.DeclineReason);

                    const updatedRequest: Request = {
                        ID: params.requestID!,
                        Status: "OPEN",
                        Project: {
                            Transaction: data.Project.Transaction === undefined ? request.Project.Transaction : data.Project.Transaction,
                            Code: data.Project.Code == undefined ? request.Project.Code : data.Project.Code
                        },
                        Partner: { Role: "PARTNER", EMail: Email, Name: partnerName, ID: Number(partnerID) },
                        Coordinator: {
                            FirstNames: data.Coordinator.FirstNames === undefined ? request.Coordinator.FirstNames : data.Coordinator.FirstNames,
                            LastNames: data.Coordinator.LastNames === undefined ? request.Coordinator.LastNames : data.Coordinator.LastNames,
                            EMail: data.Coordinator.EMail === undefined ? request.Coordinator.EMail : data.Coordinator.EMail,
                            Company: data.Coordinator.Company === undefined ? request.Coordinator.Company : data.Coordinator.Company
                        },
                        Template: { TemplateID: tempData },
                        IsAccepted: data.IsAccepted,
                        Watchers: data.Watchers
                    }
                    if (requestType !== 'approve') {
                        await httpService.saveRequest(updatedRequest, authContext?.accessToken).then(p => cleanWatchers(data)).catch(err => {
                            navigateToErrorPage(err);
                        });
                        setDisableSave(true);
                    }
                }

                if (requestType === "approve") {
                    await httpService.approveRequest(params.requestID!, authContext?.accessToken).then(() => {
                        navigate("/", { state: { status: "open" } });
                    }).catch(err => {
                        navigateToErrorPage(err);
                    });
                } else if (requestType === "decline") {
                    console.log("REASON: " + data.DeclineReason);
                    await httpService.declineRequest(params.requestID!, authContext?.accessToken, data.DeclineReason).then(() => {
                        navigate("/", { state: { status: "declined" } });
                    }).catch(err => {
                        navigateToErrorPage(err);
                    });
                } else {
                    setRequestType('');
                }
                setDisableAllButtons(false);
            }
        }
    };

    const handleAckData = (value: any) => {
        setACKGridData(value);
        if (value.length > 0) {
            setDisplay(true);
        } else {
            setDisplay(false);
        }
    }

    useEffect(() => {
        if (!requestID || !request) return;
        if (!templates || !templates.length) return;

        const foundTemplate = request.Template?.TemplateID ? templates.find(t => t.TemplateID == request.Template.TemplateID) : templates[0];
        setActiveTemplate(foundTemplate);
        setOfflineEdit(request.Offline ?? false);
        const status = request.Status;
        setToken(request.AcknowledgeToken ?? '');
        setWatchers(request.Watchers);
        setAllowChanges(status === "PENDING" && user?.Role !== 'COORDINATOR');
        if (status === "PENDING" && user?.Role === "ADMIN") {
            setAllowChanges(false);
            setAdminPending(true);
        }
        if (status === "PENDING" && user?.Role === "VIEWER") {
            setAllowChanges(false);
        }
        setIsClosed(status === "CLOSED" || status === "DECLINED");
        console.log(request);
        if (request.Status === "FAILED") {
            setAllowChanges(true);
            httpService.getPartners(authContext?.accessToken).then(response => {
                setNamesArray(response.data);
                var email = response.data[0];
                setPartnerEmail(email);
            }).catch(err => {
                navigateToErrorPage(err)
            });
        }
        setCsvName((request?.Project?.Transaction ?? "Unknown") + " Acknowledgment Report " + moment().format("MM-DD-YYYY") + ".csv");
    }, [request, templates]);

    const handleChange = (value: any, oldValue: any) => {
        if (oldValue !== value.nativeEvent.target.value) {
            setAllowSubmit(true);
        }
    };
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setFirstName(false);
        }
    }
    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setLastName(false);
        }
    }

    const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== "") {
            setCompanyName(false);
        }
    }
    // const handleEMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.value !== "") {
    //         setEMail(false);
    //     }
    // }
    const handleEMailChange = (value: any) => {
        if (value !== "") {
            setEMail(false);
        }
    }

    const reasonChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDeclineReason(e.currentTarget.value.trim());
        setIsDeclineEnabled(e.currentTarget.value.trim() === '');
    };

    const populateEmail = (e: React.FormEvent<HTMLSelectElement>) => {

        var email = namesArray.filter(x => x.ID === Number(e.currentTarget.value))[0];
        setPartnerEmail(email);
        setAllowSubmit(true);
        setDisableSave(false);

    };

    const handleApprove = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setRequestType("approve");
        }
    };
    const handleCreate = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            //setRequestType("approve");
        }
    };

    const handleSave = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setRequestType("save");
        }
    };


    const handleDecline = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setDecline(true);
        }

    };

    const handleModalDecline = async () => {
        await methods.trigger();
        if (!(Object.keys(errors).length > 0)) {
            setIsOpen(true);
        }

    };
    const handleAcknowledgementModal = async () => {
        setMessage(false);
        setFirstName(false);
        setLastName(false);
        setEMail(false);
        setCompanyName(false);
        if (isModalDialog) {
            SetIsModalDialog(false);
        } else {
            SetIsModalDialog(true);
        }

        setIsOpenAcknowledge(true);


    };

    const cleanWatchers = async (data: Request) => {
        await httpService.getWatchers(requestID, authContext?.accessToken).then(p => {
            methods.setValue("Watchers", p.data);
            setWatchers(p.data)
        }).catch(err => {
            console.log(err.response.status);
            if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                navigate("/unavailable");
            } else if (err.response.status === 500) {
                navigate("/error");
            } else if (err.response.status === 403 || err.response.status === 401) {
                navigate("/unauthorised");
            } else {
                navigate("/unavailable");
            }
        });
    };

    const [csvName, setCsvName] = useState('');

    const getFileName = () => {
        return request.Project.Transaction + " Acknowledgment Report " + moment().format("MM-DD-YYYY") + ".csv";
    }

    //VALIDATIONS

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';

    };
    const isNotFirstNameEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const isNotDefaultLetter = (value: any) => {
        return value.trim() !== '-1';
    };

    const isAccepted = (value: any) => {
        console.log(value);
        return value === true;
    };

    const KeyPress = () => {
        setAllowSubmit(true);
        setDisableSave(false);
    };

    const onAcknowledge = async () => {

        await methodss.trigger();
        setRequestType("acknowledge");
        //if (requestType === "acknowledge") {
        var token = AcknowledgeToken;
        console.log(token);
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        var emailValue = regexp.test(((document.getElementById("EMailManual")) as HTMLInputElement).value);

        if (((document.getElementById("FirstName")) as HTMLInputElement).value === "") {
            setFirstName(true);
        }
        if (((document.getElementById("LastName")) as HTMLInputElement).value === "") {
            setLastName(true);
        }
        if (((document.getElementById("EMailManual")) as HTMLInputElement).value === "") {
            setEMail(true);
        }
        if (((document.getElementById("CompanyManual")) as HTMLInputElement).value === "") {
            setCompanyName(true);
        }
        if (startDate) {
            setDateControl(false);
            setMessage(false);
        } else {
            setDateControl(true);
            setMessage(true);
        }

        if (((document.getElementById("FirstName")) as HTMLInputElement).value !== "" && ((document.getElementById("LastName")) as HTMLInputElement).value !== "" && ((document.getElementById("EMailManual")) as HTMLInputElement).value !== "" && ((document.getElementById("CompanyManual")) as HTMLInputElement).value !== "" && startDate && emailValue) {

            const newRequest: AcknowledgementManual = {
                Captcha: "",
                FirstNames: ((document.getElementById("FirstName")) as HTMLInputElement).value,
                LastNames: ((document.getElementById("LastName")) as HTMLInputElement).value,
                EMail: ((document.getElementById("EMailManual")) as HTMLInputElement).value,
                Company: ((document.getElementById("CompanyManual")) as HTMLInputElement).value,
                HasConfirmed: true,
                SignedOn: moment.utc(startDate.toString()).toDate(),
                TimeZone: moment(startDate.toISOString()).format(),
                Manual: true,
                Reason: ((document.getElementById("ManualReason")) as HTMLInputElement).value,
                Description: ((document.getElementById("ManualDescription")) as HTMLInputElement).value,
            }


            await httpService.acknowledge(token, newRequest, authContext?.accessToken).then(() => {
                setIsOpenAcknowledge(false);
                refreshData === true ? setRefresh(false) : setRefresh(true);
            }).catch(err => {

                if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                    navigate("/unavailable");
                } else if (err.response.status === 500) {
                    navigate("/error");
                } else if (err.response.status === 403 || err.response.status === 401) {
                    navigate("/unauthorised");
                } else {
                    navigate("/unavailable");
                }
            });

        }

        //}


    };

    //END VALIDATIONS
    return (
        <div>
            {
                isLoading &&
                <Loading></Loading>
            }
            {
                !isLoading &&
                <div className="request-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item active"
                            aria-current="page">{isEditing ? requestID : resources.request.new.breadcrumb}</li>
                        {
                            user?.Role === 'ADMIN' &&
                            <li className="breadcrumb-item">
                                <a href={history}>History</a>
                            </li>
                        }

                    </ol>
                    <div className="request-contentPanel">
                        <div className="request-leftPanel">
                            <FormProvider {...methods}>
                                <form noValidate onSubmit={methods.handleSubmit(onSubmit)}
                                    className={isFormInvalid ? 'was-validated' : ''}>
                                    <div>
                                        {request.Status === "OPEN" && request.AcknowledgementLink !== null && user?.Role !== "VIEWER" && isOfflineEdit !== true &&
                                            <div className="floatRElement">
                                                <button type="button" id='btnAckLink'
                                                    onClick={() => navigator.clipboard.writeText(request.AcknowledgementLink ?? '')}
                                                    className="btn btn-sm btn-primary me-2 w-40">{resources.request.acknowledgementLink.label}</button>
                                                <div className="floatElement">
                                                    <span
                                                        className='textAcknowledge'>{resources.request.acknowledgementLink.note1}</span>
                                                    <br></br>
                                                    <span
                                                        className={`textAcknowledge ${browserName === 'Chrome' ? 'padCAcknowledge' : 'padAcknowledge'}`}>{resources.request.acknowledgementLink.note2}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="request-headerSection">
                                            <span>{isEditing ? resources.request.crumb + requestID : resources.request.new.title}</span>
                                            {((user?.Role === 'COORDINATOR' && request.Status === undefined) || (user?.Role === 'PARTNER' && (request.Status === undefined || request.Status === 'PENDING'))) && (messages !== null && messages !== undefined && messages?.length !== null && messages?.length !== undefined) && messages.length > 0 &&
                                                <p className="form-check-label" style={{ "color": "black", "fontWeight": "normal" }}>{messages[0].Content}</p>
                                            }
                                        {(isOfflineEdit || !isEditing) &&
                                            <div>
                                                <label
                                                    className="form-check-label padLeft ms-2">{resources.request.new.offline}</label>
                                                <div style={{
                                                    float: "right"
                                                }}>
                                                    <input className="form-check-input"
                                                        onChange={onInputCheckboxChange}
                                                        disabled={!allowChanges || decline || user?.Role === 'VIEWER' || request.Status === 'PENDING'}
                                                        type="checkbox" defaultChecked={request.Offline}
                                                        id="isOffline"
                                                    />

                                                </div>
                                                <br></br>
                                                <label
                                                    className={`form-check-label padNote ${request.Status === 'OPEN' || request.Status === 'DECLINED' || request.Status === 'FAILED' || request.Status === 'CLOSED' || request.Status === 'PENDING' ? 'disableChek' : ''}`}>{resources.request.new.note}</label>
                                            </div>
                                        }
                                        {
                                            !isEditing &&
                                            <button type="submit"
                                                className="btn btn-sm btn-primary me-2 float-right"
                                                disabled={disableAllButtons}>{resources.request.approve}</button>
                                        }
                                        {
                                            isEditing &&
                                            !isClosed &&

                                            <button type="button" id='btnSave' onClick={handleSave}
                                                className={`btn btn-sm btn-primary me-2 float-right`}
                                                disabled={(!allowSubmit || disableAllButtons) || disableSave}>{resources.request.save}</button>

                                        }
                                        {/* <div className="col-md d-flex flex-row-reverse"> */}
                                        {
                                            isEditing
                                            &&
                                            allowChanges
                                            &&
                                            <div>
                                                <button disabled={disableAllButtons} type="button"
                                                    onClick={handleApprove}
                                                    className="btn btn-sm btn-primary me-2 float-right">{resources.request.approve}</button>
                                                <button disabled={disableAllButtons} type="button"
                                                    onClick={handleDecline}
                                                    className="btn btn-sm btn-primary me-2 float-right">{resources.request.decline}</button>
                                            </div>
                                        }
                                        {/* </div> */}

                                    </div>
                                    <div className="request">
                                        {request.Status === 'DECLINED' && request.DeclineReason &&
                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="form-group mb-3">
                                                        <label className="form-label">Declined by Admin</label>
                                                        <input readOnly={!allowChanges}
                                                            className={`form-control`}
                                                            type="text"
                                                            defaultValue={request.DeclineReason}
                                                            id="DeclineReason"
                                                            {...methods.register('DeclineReason', {
                                                                disabled: !allowChanges || decline
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {request.Status === 'CLOSED' && request.CloseReason &&
                                            <div>
                                                <label className="form-label">Closed by Admin or TC</label>
                                                <div className="row">
                                                    <div className="col-md">
                                                        <div className="form-group mb-3">

                                                            <input readOnly={!allowChanges}
                                                                className={`form-control`}
                                                                type="text"
                                                                defaultValue={request.CloseReason}
                                                                id="CloseReason"
                                                                {...methods.register('DeclineReason', {
                                                                    disabled: !allowChanges || decline
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {(user?.Role === 'ADMIN' || user?.Role === 'VIEWER') && request.Status !== "FAILED" &&
                                            <div>
                                                <p>{resources.request.partner.title}</p>
                                                <div className="row">
                                                    <div className="col-md">

                                                        <div className="form-group mb-3">
                                                            <label
                                                                className="form-label">{resources.request.partner.name.label}</label>
                                                            <input readOnly={!allowChanges}
                                                                className={`form-control ${errors['Partner']?.Name ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                defaultValue={request.Partner?.Name}
                                                                placeholder={resources.request.partner.name.placeholder}
                                                                id="FirstNames"
                                                                {...methods.register('Partner.Name', {
                                                                    required: resources.request.partner.name.required,
                                                                    validate: { 'Partner.Name': e => isNotEmpty(e) || resources.request.partner.name.validation },
                                                                    onBlur(event) {
                                                                        handleChange(event, request.Partner?.Name)
                                                                    },
                                                                    disabled: !allowChanges || decline
                                                                })}
                                                            />
                                                            {errors['Partner']?.Name &&
                                                                <div className="invalid-feedback">
                                                                    <span>{resources.request.partner.name.required}</span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <label
                                                            className="form-label">{resources.request.partner.eMail.label}</label>
                                                        <input readOnly={!allowChanges}
                                                            className={`form-control ${errors['Partner']?.EMail ? 'is-invalid' : ''}`}
                                                            defaultValue={request.Partner?.EMail}
                                                            type="text"
                                                            placeholder={resources.request.partner.eMail.placeholder}
                                                            id="Email"
                                                            {...methods.register('Partner.EMail', {
                                                                required: resources.request.partner.eMail.required,
                                                                pattern: {
                                                                    value: /^\S+@\S+\.\S+$/,
                                                                    message: resources.request.partner.eMail.validation,
                                                                },
                                                                onBlur(event) {
                                                                    handleChange(event, request.Partner?.EMail)
                                                                },
                                                                disabled: !allowChanges || decline
                                                            })} />
                                                        {errors['Partner']?.EMail &&
                                                            <div className="invalid-feedback">
                                                                <span>{resources.request.partner.eMail.required}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {(((user?.Role === 'ADMIN' || user?.Role === 'VIEWER') && request.Status === "FAILED") || (user?.Role === 'COORDINATOR' && request?.Status === undefined)) &&
                                            <div className="form-group mb-3">
                                                <p>{resources.request.partner.title}</p>
                                                <div className="row">

                                                    <div className="col-md">
                                                        <div className="form-group mb-3">
                                                            <label
                                                                className="form-label">{resources.request.partner.name.labelPartner}</label>
                                                            <select disabled={user?.Role === 'VIEWER'}
                                                                className="form-select" id="PartnerName"
                                                                onChange={populateEmail}>
                                                                {/* <option>Select</option> */}
                                                                {namesArray.map((user) => (
                                                                    <option key={user.Name}
                                                                        value={user.ID}>{user.Name}</option>
                                                                ))}
                                                            </select>
                                                            {errors['Partner']?.Name &&
                                                                <div className="invalid-feedback">
                                                                    <span>{resources.request.partner.name.required}</span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-group mb-3">
                                                            <label
                                                                className="form-label">{resources.request.partner.eMail.labelEmailPartner}</label>
                                                            <select disabled={user?.Role === 'VIEWER'}
                                                                className="form-select"
                                                                id="PartnerEmail">
                                                                {/* <option>Select</option> */}
                                                                <option key={partnerEmail?.EMail}
                                                                    value={partnerEmail?.EMail}>{partnerEmail?.EMail}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {user?.Role === 'COORDINATOR' && request.Status !== undefined &&
                                            <div className="form-group mb-3">
                                                <p>{resources.request.partner.title}</p>
                                                <div className="row">


                                                    <div className="col-md">
                                                        <div className="form-group mb-3">
                                                            <label
                                                                className="form-label">{resources.request.partner.name.label}</label>
                                                            <input readOnly={!allowChanges}
                                                                className={`form-control ${errors['Partner']?.Name ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                defaultValue={request.Partner?.Name}
                                                                placeholder={resources.request.partner.name.placeholder}
                                                                id="FirstNames"
                                                                {...methods.register('Partner.Name', {
                                                                    required: resources.request.partner.name.required,
                                                                    validate: { 'Partner.Name': e => isNotEmpty(e) || resources.request.partner.name.validation },
                                                                    onBlur(event) {
                                                                        handleChange(event, request.Partner?.Name)
                                                                    },
                                                                    disabled: !allowChanges || decline
                                                                })}
                                                            />
                                                            {errors['Partner']?.Name &&
                                                                <div className="invalid-feedback">
                                                                    <span>{resources.request.partner.name.required}</span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-group mb-3">
                                                            <label
                                                                className="form-label">{resources.request.partner.eMail.label}</label>
                                                            <input readOnly={!allowChanges}
                                                                className={`form-control ${errors['Partner']?.EMail ? 'is-invalid' : ''}`}
                                                                defaultValue={request.Partner?.EMail}
                                                                type="text"
                                                                placeholder={resources.request.partner.eMail.placeholder}
                                                                id="Email"
                                                                {...methods.register('Partner.EMail', {
                                                                    required: resources.request.partner.eMail.required,
                                                                    pattern: {
                                                                        value: /^\S+@\S+\.\S+$/,
                                                                        message: resources.request.partner.eMail.validation,
                                                                    },
                                                                    onBlur(event) {
                                                                        handleChange(event, request.Partner?.EMail)
                                                                    },
                                                                    disabled: !allowChanges || decline
                                                                })} />
                                                            {errors['Partner']?.EMail &&
                                                                <div className="invalid-feedback">
                                                                    <span>{resources.request.partner.eMail.required}</span>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        <p>{resources.request.coordinator.title}</p>
                                        <div className="form-group mb-3">
                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="form-group mb-3">
                                                        <label
                                                            className="form-label">{resources.request.coordinator.firstName.label}</label>
                                                        <input onKeyDown={KeyPress} readOnly={request.Status === 'CLOSED' || request.Status === 'DECLINED' || user?.Role === 'VIEWER'}
                                                            className={`form-control ${errors['Coordinator']?.FirstNames ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            defaultValue={request.Coordinator?.FirstNames}
                                                            placeholder={resources.request.coordinator.firstName.placeholder}
                                                            id="FirstNames"
                                                            {...methods.register('Coordinator.FirstNames', {
                                                                required: resources.request.coordinator.firstName.required,
                                                                validate: { 'Coordinator.FirstNames': e => isNotEmpty(e) || resources.request.coordinator.firstName.validation },
                                                                onBlur(event) {
                                                                    handleChange(event, request.Coordinator?.FirstNames)
                                                                },
                                                                disabled: decline || request.Status === 'DECLINED' || user?.Role === 'VIEWER' || (user?.Role === 'COORDINATOR' && (request.Status === "CLOSED" || request.Status === "OPEN" || request.Status === "DECLINED" || request.Status === "FAILED" || request.Status === "PENDING")) || (user?.Role === 'ADMIN' && request.Status !== 'OPEN' && request.Status !== 'FAILED')
                                                            })}
                                                        />
                                                        {errors['Coordinator']?.FirstNames &&
                                                            <div className="invalid-feedback">
                                                                <span>{resources.request.coordinator.firstName.required}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                                <div className="col-md">
                                                    <div className="form-group mb-3">
                                                        <label
                                                            className="form-label">{resources.request.coordinator.lastName.label}</label>
                                                        <input onKeyDown={KeyPress} readOnly={request.Status === 'CLOSED' || request.Status === 'DECLINED' || user?.Role === 'VIEWER'}
                                                            className={`form-control ${errors['Coordinator'] ? 'is-invalid' : ''}`}
                                                            type="text"
                                                            defaultValue={request.Coordinator?.LastNames}
                                                            placeholder={resources.request.coordinator.lastName.placeholder}
                                                            id="LastNames"
                                                            {...methods.register('Coordinator.LastNames', {
                                                                required: resources.request.coordinator.lastName.required,
                                                                validate: { 'Coordinator.LastNames': e => isNotEmpty(e) || resources.request.coordinator.lastName.validation },
                                                                onBlur(event) {
                                                                    handleChange(event, request.Coordinator?.LastNames)
                                                                },
                                                                disabled: decline || request.Status === 'DECLINED' || user?.Role === 'VIEWER' || (user?.Role === 'COORDINATOR' && (request.Status === "CLOSED" || request.Status === "OPEN" || request.Status === "DECLINED" || request.Status === "FAILED" || request.Status === "PENDING")) || (user?.Role === 'ADMIN' && request.Status !== 'OPEN' && request.Status !== 'FAILED')
                                                            })}
                                                        />
                                                        {errors['Coordinator']?.LastNames &&
                                                            <div className="invalid-feedback">
                                                                <span>{resources.request.coordinator.lastName.required}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.coordinator.eMail.label}</label>
                                                    <input onKeyDown={KeyPress} readOnly={request.Status === 'CLOSED' || request.Status === 'DECLINED' || user?.Role === 'VIEWER'}
                                                        className={`form-control ${errors['Coordinator']?.EMail ? 'is-invalid' : ''}`}
                                                        defaultValue={request.Coordinator?.EMail}
                                                        type="text"
                                                        placeholder={resources.request.coordinator.eMail.placeholder}
                                                        id="Email"
                                                        {...methods.register('Coordinator.EMail', {
                                                            required: resources.request.coordinator.eMail.required,
                                                            pattern: {
                                                                value: /^\S+@\S+\.\S+$/,
                                                                message: resources.request.coordinator.eMail.validation,
                                                            },
                                                            onBlur(event) {
                                                                handleChange(event, request.Coordinator?.EMail)
                                                            },
                                                            disabled: decline || request.Status === 'DECLINED' || user?.Role === 'VIEWER' || (user?.Role === 'COORDINATOR' && (request.Status === "CLOSED" || request.Status === "OPEN" || request.Status === "DECLINED" || request.Status === "FAILED" || request.Status === "PENDING")) || (user?.Role === 'ADMIN' && request.Status !== 'OPEN' && request.Status !== 'FAILED')
                                                        })} />
                                                    {errors['Coordinator']?.EMail &&
                                                        <div className="invalid-feedback">
                                                            <span>{resources.request.coordinator.eMail.required}</span>
                                                        </div>}
                                                </div>
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.coordinator.legalEntity.label}</label>
                                                    <input onKeyDown={KeyPress} readOnly={request.Status === 'CLOSED' || request.Status === 'DECLINED' || user?.Role === 'VIEWER'}
                                                        className={`form-control ${errors['Coordinator']?.Company ? 'is-invalid' : ''}`}
                                                        defaultValue={request.Coordinator?.Company}
                                                        type="text"
                                                        placeholder={resources.request.coordinator.legalEntity.placeholder}
                                                        id="Company"
                                                        {...methods.register('Coordinator.Company', {
                                                            required: resources.request.coordinator.legalEntity.required,
                                                            validate: { 'Coordinator.Company': e => isNotEmpty(e) || resources.request.coordinator.legalEntity.validation },
                                                            onBlur(event) {
                                                                handleChange(event, request.Coordinator?.Company)
                                                            },
                                                            disabled: decline || request.Status === 'DECLINED' || user?.Role === 'VIEWER' || (user?.Role === 'COORDINATOR' && (request.Status === "CLOSED" || request.Status === "OPEN" || request.Status === "DECLINED" || request.Status === "FAILED" || request.Status === "PENDING")) || (user?.Role === 'ADMIN' && request.Status !== 'OPEN' && request.Status !== 'FAILED')
                                                        })} />
                                                    {errors['Coordinator']?.Company &&
                                                        <div className="invalid-feedback">
                                                            <span>{resources.request.coordinator.legalEntity.required}</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.project.transaction.label}</label>
                                                    <input readOnly={!allowChanges}
                                                        className={`form-control ${errors['Project']?.Transaction ? 'is-invalid' : ''}`}
                                                        defaultValue={request.Project?.Transaction}
                                                        type="text"
                                                        placeholder={resources.request.project.transaction.placeholder}
                                                        id="Transaction"
                                                        {...methods.register('Project.Transaction', {
                                                            required: resources.request.project.transaction.required,
                                                            validate: { 'Project.Transaction': e => isNotEmpty(e) || resources.request.project.transaction.validation },
                                                            onBlur(event) {
                                                                handleChange(event, request.Project?.Transaction)
                                                            },
                                                            disabled: !allowChanges || decline || user?.Role === 'VIEWER'
                                                        })} />
                                                    {errors['Project']?.Transaction &&
                                                        <div className="invalid-feedback">
                                                            <span>{resources.request.project.transaction.required}</span>
                                                        </div>}
                                                </div>
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.project.caseCode.label}</label>
                                                    <input onKeyDown={KeyPress}
                                                        readOnly={request.Status === 'CLOSED' || request.Status === 'DECLINED' || user?.Role === 'VIEWER' || isOfflineEdit === true}
                                                        className={`form-control ${errors['Coordinator'] ? 'is-invalid' : ''}`}
                                                        defaultValue={request.Project?.Code} type="text"
                                                        placeholder={resources.request.project.caseCode.placeholder}
                                                        id="CaseCode"
                                                        {...methods.register('Project.Code', {
                                                            onBlur(event) {
                                                                handleChange(event, request.Project?.Code)
                                                            },
                                                            disabled: request.Status === 'CLOSED' || request.Status === 'DECLINED' || decline || user?.Role === 'VIEWER' || isOfflineEdit
                                                        })} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.nrl.template.label}</label>
                                                    <select
                                                        className={`form-control ${errors['Template']?.TemplateID ? 'is-invalid' : ''}`}
                                                        defaultValue={`${isEditing ? activeTemplate?.MasterID : templates[0]}`}
                                                        id="TemplateID"
                                                        {...methods.register('Template.TemplateID', {
                                                            required: resources.request.nrl.template.required,
                                                            validate: { 'Template.TemplateID': e => isNotDefaultLetter(e) || resources.request.nrl.template.validation },
                                                            disabled: !allowChanges || decline || user?.Role === 'VIEWER',
                                                            onChange(event) {
                                                                return event.target.value;
                                                            },
                                                            onBlur(event) {
                                                                handleChange(event, request.Template?.TemplateID)
                                                            },
                                                        })}
                                                    >
                                                        {templates.map((template) => (
                                                            <option key={template.MasterID}
                                                                defaultValue={template.MasterID}
                                                                value={template.MasterID}>{template.Name}</option>
                                                        ))}

                                                    </select>
                                                    {errors['Template']?.TemplateID &&
                                                        <div className="invalid-feedback">
                                                            <span>{resources.request.nrl.template.required}</span>
                                                        </div>}
                                                </div>
                                                <div className="col-md">
                                                </div>
                                            </div>


                                            {isFetching && <Loading></Loading>}
                                            {!isFetching && !isOfflineEdit && !isOffline &&
                                                <Watchers isNew={!isEditing} enableButton={setAllowSubmit}
                                                    enableSave={setDisableSave}
                                                    authContext={authContext}
                                                    Watchers={watchers} requestID={requestID}
                                                    enabled={(request.Status === "OPEN" || request.Status === "PENDING" || !isEditing || request.Status === "FAILED") && user?.Role !== 'VIEWER'} />}

                                            <div className="row">
                                                <div className="form-check form-group mb-3">

                                                    {
                                                        allowChanges &&
                                                        <div>
                                                            <input
                                                                className={`form-check-input ${errors['IsAccepted'] ? 'is-invalid' : ''}`}
                                                                defaultChecked={request.IsAccepted && request.Status !== "FAILED" && request.Status !== "PENDING"}
                                                                type="checkbox" id="IsAccepted"
                                                                {...methods.register('IsAccepted', {
                                                                    validate: { isAccepted: e => (isAccepted(e)) || resources.request.responsibility.validation },
                                                                    disabled: !allowChanges || decline || user?.Role === 'VIEWER'
                                                                })}
                                                            />
                                                            <label
                                                                className="form-check-label">{resources.request.responsibility.label}</label>
                                                            {errors['IsAccepted'] &&
                                                                <div className="invalid-feedback">
                                                                    <span>{resources.request.responsibility.required}</span>
                                                                </div>}
                                                        </div>
                                                    }
                                                    {!allowChanges &&
                                                        <div>
                                                            <input disabled className={`form-check-input`}
                                                                defaultChecked={request.IsAccepted}
                                                                type="checkbox"
                                                                id="IsAccepted" />
                                                            <label
                                                                className="form-check-label">{resources.request.responsibility.label}</label>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row request-button-row">
                                                <div className="col-md">
                                                </div>
                                                <div className="col-md d-flex flex-row-reverse">
                                                    {
                                                        !isEditing && user?.Role !== 'COORDINATOR' &&
                                                        <button type="submit"
                                                            className="btn btn-sm btn-primary me-2"
                                                            disabled={disableAllButtons}>{resources.request.approve}</button>
                                                    }
                                                    {
                                                        !isEditing && user?.Role === 'COORDINATOR' &&
                                                        <div>
                                                            <button type="submit"
                                                                className="btn btn-sm btn-primary me-2"
                                                                disabled={disableAllButtons}>{resources.acknowledgement.submit}</button>
                                                            <button onClick={() => {
                                                                navigate("/");
                                                            }} className="btn btn-sm btn-primary me-2"
                                                                disabled={disableAllButtons}>{resources.acknowledgement.back}</button>
                                                        </div>
                                                    }
                                                    {
                                                        isEditing &&
                                                        !isClosed &&
                                                        <div>
                                                            <button type="button" id='btnSave' onClick={handleSave}
                                                                className={`btn btn-sm btn-primary me-2`}
                                                                disabled={!allowSubmit || disableAllButtons || disableSave}>{resources.request.save}</button>
                                                        </div>
                                                    }

                                                    {user?.Role === 'ADMIN' && request.Status === 'OPEN' &&
                                                        <div>
                                                            <button type="button" id='btnClose'
                                                                onClick={() => setIsOpen(true)}
                                                                className={`btn btn-sm btn-primary me-2`}>Close
                                                            </button>
                                                            <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                                                                <Modal.Header>Confirmation</Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="col-md d-flex flex-row-reverse">
                                                                        <div className="col-md">
                                                                            <label
                                                                                className="form-label">{resources.request.declineReason.Close}</label>
                                                                            <input onChange={reasonChange}
                                                                                className='form-control'
                                                                                defaultValue={declineReason}
                                                                                type="text"
                                                                                placeholder={resources.request.declineReason.placeholder}
                                                                                id="CloseReason" />
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <div className="row button-row">
                                                                        <button type="button"
                                                                            disabled={isDeclineEnabled}
                                                                            onClick={closeMe}
                                                                            className="btn btn-sm btn-primary me-2">Confirm
                                                                        </button>
                                                                    </div>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>

                                                    }
                                                </div>
                                            </div>

                                            <div className="row request-button-row">
                                                <div className="col-md">
                                                </div>
                                                <div className="col-md d-flex flex-row-reverse">
                                                    {
                                                        ((isEditing && allowChanges)
                                                            ||
                                                            adminPending)
                                                        &&
                                                        <div>
                                                            {user?.Role === 'PARTNER' &&
                                                                <div>
                                                                    <button disabled={disableAllButtons} type="submit"
                                                                        onClick={handleApprove}
                                                                        className="btn btn-sm btn-primary me-2">{resources.request.approve}</button>
                                                                    <button disabled={disableAllButtons} type="button"
                                                                        onClick={handleDecline}
                                                                        className="btn btn-sm btn-primary me-2">{resources.request.decline}</button>
                                                                </div>
                                                            }

                                                            {user?.Role === 'ADMIN' &&
                                                                <div>

                                                                    <button disabled={disableAllButtons} type="button"
                                                                        onClick={handleModalDecline}
                                                                        className="btn btn-sm btn-primary me-2">{resources.request.decline}</button>
                                                                    <Modal show={isOpen}
                                                                        onHide={() => setIsOpen(false)}>
                                                                        <Modal.Header>Confirmation</Modal.Header>
                                                                        <Modal.Body>
                                                                            <div
                                                                                className="col-md d-flex flex-row-reverse">
                                                                                <div className="col-md">
                                                                                    <label
                                                                                        className="form-label">{resources.request.declineReason.label}</label>
                                                                                    <input onChange={reasonChange}
                                                                                        className='form-control'
                                                                                        defaultValue={declineReason}
                                                                                        type="text"
                                                                                        placeholder={resources.request.declineReason.placeholder}
                                                                                        id="DeclineReason" />
                                                                                </div>
                                                                            </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <div className="row button-row">
                                                                                <button type="button"
                                                                                    disabled={isDeclineEnabled}
                                                                                    onClick={declineMe}
                                                                                    className="btn btn-sm btn-primary me-2">Confirm
                                                                                </button>
                                                                            </div>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                        <div className="request-rightPanel">
                            <FormProvider {...methodss}>
                                <form noValidate onSubmit={methodss.handleSubmit(onSubmitManual)}
                                    className={isFormInvalid ? 'was-validated' : ''}>
                                    <Modal show={isOpenAcknowledge} onHide={() => setIsOpenAcknowledge(false)}>
                                        <Modal.Header>Acknowledgment
                                            <button type="button" id='btnClose'
                                                onClick={() => setIsOpenAcknowledge(false)}
                                                className='modalClose'>X</button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="form-group mb-3">
                                                <label
                                                    className="form-label">{resources.acknowledgement.firstName.label}</label>
                                                <input className={`form-control ${isFirstName ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder={resources.acknowledgement.firstName.placeHolder}
                                                    id="FirstName"
                                                    onChange={handleFirstNameChange}
                                                />
                                                {isFirstName === true && <div className="inValidDate">
                                                    <span>{resources.acknowledgement.firstName.required}</span></div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label
                                                    className="form-label">{resources.acknowledgement.lastName.label}</label>
                                                <input className={`form-control ${isLastName ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder={resources.acknowledgement.lastName.placeHolder}
                                                    id="LastName"
                                                    onChange={handleLastNameChange}
                                                />
                                                {isLastName && <div className="inValidDate">
                                                    <span>{resources.acknowledgement.lastName.required}</span></div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label
                                                    className="form-label">{resources.acknowledgement.eMail.label}</label>
                                                <input className={`form-control ${isEMail ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder={resources.acknowledgement.eMail.placeHolder}
                                                    id="EMailManual"
                                                    {...methodss.register('EMail', {
                                                        //required: resources.acknowledgement.eMail.required,
                                                        onChange: (event) => {
                                                            handleEMailChange(event.target.value)
                                                        },
                                                        pattern: {
                                                            value: /^\S+@\S+\.\S+$/,
                                                            message: resources.acknowledgement.eMail.validation,
                                                        }
                                                    })}
                                                />
                                                {isEMail && <div className="inValidDate">
                                                    <span>{resources.acknowledgement.eMail.required}</span></div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label
                                                    className="form-label">{resources.acknowledgement.legalEntity.label}</label>
                                                <input className={`form-control ${isCompanyName ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder={resources.acknowledgement.legalEntity.placeHolder}
                                                    id="CompanyManual"
                                                    onChange={handleCompanyChange}
                                                // {...methodss.register('Company', {
                                                //     required: resources.acknowledgement.legalEntity.required,
                                                //     validate: { isEmpty: e => isNotEmpty(e) || resources.acknowledgement.legalEntity.validation }
                                                // })} 
                                                />
                                                {isCompanyName && <div className="inValidDate">
                                                    <span>{resources.acknowledgement.legalEntity.required}</span></div>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <label
                                                    className="form-label">{resources.acknowledgement.requestDate.label}</label>
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: message,
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <div
                                                            className={`form-group ${isDateControl ? 'was-validated' : ''}`}>
                                                            <BCGDatePicker id="RequestDate" selected={startDate}
                                                                onChange={(date: React.SetStateAction<Date | undefined>) => {
                                                                    if (date) {
                                                                        onChange(date);
                                                                        setStartDate(date)
                                                                    }
                                                                }}
                                                                maxDate={new Date()}
                                                                useWeekdaysShort={true}
                                                                placeholderText={resources.acknowledgement.requestDate.placeHolder} />
                                                        </div>
                                                    )}
                                                    name="SignedOn"
                                                />
                                                {!startDate && message === true &&
                                                    <span className="inValidDate">Request Date is required.</span>}
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.declineReason.Description}</label>
                                                    <input className='form-control' type="text"
                                                        placeholder={resources.request.declineReason.Desc}
                                                        id="ManualDescription" />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="col-md">
                                                    <label
                                                        className="form-label">{resources.request.declineReason.Manual}</label>
                                                    <input className='form-control' type="text"
                                                        placeholder={resources.request.declineReason.placeholder}
                                                        id="ManualReason" />
                                                </div>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/* <button type="button" id='btnClose' onClick={() => setIsOpenAcknowledge(false)} className={`btn btn-text me-1`}  >Close</button> */}
                                            <button type="button" onClick={onAcknowledge}
                                                className="btn btn-primary">Save
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                    {(user?.Role === 'COORDINATOR' || user?.Role === 'PARTNER') && request.Status === "OPEN" &&
                                        <button type="button" id='btnNew' onClick={handleAcknowledgementModal}
                                            className={`btn btn-sm btn-primary me-2`} style={{
                                                float: "right"
                                            }}>+ New Acknowledgment</button>
                                    }
                                    {(user?.Role === 'COORDINATOR' || user?.Role === 'PARTNER' || user?.Role === 'ADMIN') && (request.Status === "OPEN" || request.Status === "CLOSED") && displayDownload &&
                                        <>

                                            <span><Link style={{ textDecoration: 'none' }} to={acknowledgementCSV}
                                                target="_blank" download={csvName}>
                                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5 18V13.5862H6.5V16.5H17.5V13.5862H19V18H5Z"
                                                        fill="#1F2328" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.75 11.1287L14.5253 9.35331L15.586 10.414L12 14L8.41393 10.414L9.47459 9.35331L11.25 11.1287V6H12.75V11.1287Z"
                                                        fill="#1F2328" />
                                                </svg>
                                            </Link></span>

                                        </>
                                    }
                                    <Acknowledgement requestID={params.requestID!} authContext={authContext} user={user}
                                        status={request.Status} data={refreshData}></Acknowledgement>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default RequestForm;
