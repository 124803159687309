const envSettings = (window as any);

const config = {
    oidc: {
        clientId: envSettings.CLIENT_ID,
        issuer: envSettings.ISSUER,
        redirectPath: `/${envSettings.REDIRECT_PATH}`,
        redirectUri: `${window.location.origin}/${envSettings.REDIRECT_PATH}`,
        scopes: envSettings.SCOPES.split(' '),
        pkce: false
    },
    apiKey: envSettings.API_KEY,
    apiUrl: envSettings.API_URL,
    rumEnvironment: envSettings.RUMENVIRONMENT,
    rumApplicationID: envSettings.RUMAPPLICATIONID,
    rumClientToken: envSettings.RUMCLIENTTOKEN,
    rumService: envSettings.RUMSERVICE,
};

export default config;