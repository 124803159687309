import resources from '../../resources.json';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import httpService from "../../services/httpService";
import { UserInfo } from "../../custom";
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import './../confirmation-error/SuccessConfirmation.css'

type PageProps = {
    userInfo?: UserInfo | null,
    authContext?: AuthState | null,
};

const SuccessConfirmation = ({ userInfo, authContext }: PageProps) => {

    return (
        <div>

            {
                <div className="container">
                    <div>
                        <div>
                            <p className='title'>{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="contentPanel">
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z" fill="green" /></svg>
                                <div className='messageDetails'>
                                    {resources.confirmationText.successMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default SuccessConfirmation;
