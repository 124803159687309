import {useNavigate} from "react-router-dom";

const useErrorPages = () => {
    const navigate = useNavigate();
    const navigateToErrorPage = (err: any) => {
        console.log(err.response.status);
        switch (err.response.status) {
            case 404:
            case 400:
            case 405:
                navigate("/unavailable");
                break;
            case 500:
                navigate("/error");
                break;
            case 403:
            case 401:
                navigate("/unauthorised");
                break;
            default:
                navigate("/unavailable");
                break;
        }
    };
    return {navigateToErrorPage};
};

export default useErrorPages;

