
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Request } from "../../Models/Model"
import './Acknowledgement.css'
import { Button, Modal } from 'react-bootstrap';
import httpService from "../../services/httpService";
import { UserInfo } from "../../custom";
import { AuthState } from '../../custom';
import { useNavigate } from 'react-router-dom';




type PageProps = {
  userInfo?: UserInfo | null,
  authContext?: AuthState | null
};

const Acknowledgement = ({ ...props }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<Request>({ shouldUseNativeValidation: true, shouldFocusError: false });
  const [gridData, setGridData] = useState<any[]>([]);
  const [displayControl, setDisplay] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [acknowledgeID, setAcknowledgeID] = useState('');
  useEffect(() => {
    (async () => {

      await httpService.getAcknowledgement(props.requestID, props.authContext?.accessToken).then(response => handleData(response.data)).catch(err => {
        console.log(err.response.status);
        if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
          navigate("/unavailable");
        }
        else if (err.response.status === 500) {
          navigate("/error");
        }
        else if (err.response.status === 403 || err.response.status === 401) {
          navigate("/unauthorised");
        }
        else {
          navigate("/unavailable");
        }
      });

    })();

    return () => {

      // this now gets called when the component unmounts
    };
  }, [props.data]);

  const handleData = (value: any) => {

    setGridData(value);
    if (value.length > 0) {
      setDisplay(true);
    }
    else {
      setDisplay(false);
    }

  };
  const GetAcknowledgement = async () => {
    await httpService.getAcknowledgement(props.requestID, props.authContext?.accessToken).then(response => handleData(response.data)).catch(err => {
      console.log(err.response.status);
      if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
        navigate("/unavailable");
      }
      else if (err.response.status === 500) {
        navigate("/error");
      }
      else if (err.response.status === 403 || err.response.status === 401) {
        navigate("/unauthorised");
      }
      else {
        navigate("/unavailable");
      }
    });

  };
  const handleAcknowledgementModal = async (acknowledgeID: string) => {
    setAcknowledgeID(acknowledgeID);
    setIsModalOpen(true);
  };
  const closeModal = async () => {

    setIsModalOpen(false);
  };
  const DeleteAcknowledgement = async (value: string) => {
    setDisableButtons(true);
    //acknowledgeID
    await httpService.deleteAcknowledgement(acknowledgeID,props.requestID, props.authContext?.accessToken).then(response => GetAcknowledgement()).catch(err => {
      console.log(err.response.status);
      if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
        navigate("/unavailable");
      }
      else if (err.response.status === 500) {
        navigate("/error");
      }
      else if (err.response.status === 403 || err.response.status === 401) {
        navigate("/unauthorised");
      }
      else {
        navigate("/unavailable");
      }
    });
    setIsModalOpen(false);
    setDisableButtons(false);
  };
  return (

    <div>
      {
        displayControl &&
        <div className="container borderContainer">

          <div className="request-contentPanel">
            <div className="request-leftPanel">
              <form>
                <div className="headerSection">
                  <p>Request Acknowledgment</p>

                  {/* <button type="submit"
                    className="btn btn-sm btn-primary me-2 float-right">Submit
                  </button> */}
                </div>
                <div className="request">
                  <div className="sorted">Sorted By Latest</div>
                  <div className="form-group mb-3">
                    {gridData.map((data) => (


                      <div className="form-group mb-3">
                        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                          <Modal.Header>Delete Confirmation</Modal.Header>
                          <Modal.Body>Are you sure you want to delete the acknowledgment?</Modal.Body>
                          <Modal.Footer>
                            <div className="row button-row">
                              <div className="col-md d-flex flex-row-reverse">
                                <button type="button" disabled={disableButtons} onClick={() => DeleteAcknowledgement(data.AcknowledgementID)} className="btn btn-sm btn-primary me-2">Yes</button>
                                <button type="button" disabled={disableButtons} onClick={closeModal} className="btn btn-sm btn-primary me-2">Cancel</button>
                              </div>
                            </div>
                          </Modal.Footer>
                        </Modal>
                        <label className="labelField">{data.LastNames} , {data.FirstNames}</label>
                        {(data.Manual === true && props.status === "OPEN") && (props?.user.Role === 'COORDINATOR' || props?.user.Role === 'PARTNER') &&
                          <div style={{
                            float: "right",
                            cursor:"pointer"
                          }} onClick={() => {
                            { handleAcknowledgementModal(data.AcknowledgementID) };
                          }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>

                          </div>
                        }<br></br>
                        <label className="labelField"><b>{data.Company}</b></label><br></br>
                        <label className="labelField"><b>{data.EMail}</b></label><br></br>
                        <label className="labelField"><b>Acknowledged on : {data.LastAcknowledged}</b></label><br></br>

                        {data.Manual === true && 
                          <div>
                            <label className="labelManual"><b>Manually Added </b></label><br></br>
                          </div>

                        }

                      </div>

                    ))}

                  </div>
                </div>
              </form>
            </div>
            <div className="request-rightPanel sorted">
              {gridData.length} Results
            </div>
          </div>
        </div>
      }
    </div>
  )
};

export default Acknowledgement;
