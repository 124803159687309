import { Statistic } from '../../Models/Statistic';

type Props = {
    statistic?: Statistic | null,
    tab?: number | null,
};

const AnalyticsList = (props: Props) => {

    let value = !props.statistic?.IsPercent ? props.statistic?.Value?.toString() + '' : props.statistic?.Value?.toFixed(2) + '%';

    const tab = (props.tab ? props.tab : 0) + 1;

    const divStyle = {
        paddingLeft: 20 * tab,
        paddingTop: 10,
    };

    const subList = props.statistic?.SubList?.map((item) => {
        return (
            <AnalyticsList key={item.ID} statistic={item} tab={tab} />
        )
    });

    return (
        <>
            <div ><strong>{props.statistic?.Title}</strong>: {value}</div>
            {subList}
        </>
    );
}

export default AnalyticsList;
