import React from 'react';

import { AuthContext } from '../../contexts/AuthContext';
import TopNavBar from '../navbar/TopNavBar';
import './Layout.css'
import Loading from '../routeHandler/Loading';

const Layout = ({ children }: any) => {
    return (
        <div className='nrlApp'>
            <AuthContext.Consumer>
                {(authContext) => {
                    return (
                        <React.Fragment>
                            {
                                authContext?.authState?.isAuthenticated &&
                                <TopNavBar
                                    user = {authContext.user}
                                    accessToken={authContext.authState?.accessToken?.accessToken}
                                />
                            }
                            <div className="mainPanel">
                                {
                                    !authContext?.authState?.isAuthenticated &&
                                    <main>{children}</main>

                                }
                                {
                                    !authContext?.user && 
                                    <Loading></Loading>
                                }
                                {
                                    authContext?.user && 
                                    <main>{children}</main>
                                
                                }
                                
                            </div>
                        </React.Fragment>
                    );
                }}
            </AuthContext.Consumer>
        </div>
    );
};
export default Layout;