import resources from '../../resources.json';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import BCGReactTable from '@bcg-web/bcg-react-table';
import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import httpService from "../../services/httpService";
import './AcknowledgementTable.css';
import { AcknowledgementCount } from "../../Models/AcknowledgementCount";
import { State } from '../../Models/State';
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import { User } from '../../Models/User';
import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';

type Props = {
    authContext?: AuthState | null,
    user?: User | null
};

const AcknowledgementsTable = (props: Props) => {
    const [tableData, setTableData] = useState<AcknowledgementCount[]>([]);
    const [state, setState] = useState(State.LOADING);
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 768;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        (async () => {
            await httpService.getAcknowledgmentCounts(props.authContext)
                .then(response => {
                    setState(State.OK);
                    setTableData(response);
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }, []);

    const columns = [
        {
            header: resources.dashboard.acknowledgementsGrid.firstNames,
            accessorKey: 'FirstNames',
        },

        {
            header: resources.dashboard.acknowledgementsGrid.lastNames,
            accessorKey: 'LastNames',
        },
        {
            header: resources.dashboard.acknowledgementsGrid.eMail,
            accessorKey: 'EMail',
        },
        {
            header: resources.dashboard.acknowledgementsGrid.legalEntity,
            accessorKey: 'Company',
        },
        {
            header: resources.dashboard.acknowledgementsGrid.count,
            accessorKey: 'Count',
            cell: ({ cell }: any) => (
                <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">{cell.getValue()}</span>
            ),
        },
        {
            header: resources.dashboard.acknowledgementsGrid.lastAcknowledgement,
            accessorKey: 'LastAcknowledgedOn',
            cell: ({ cell }: any) => (
                <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>
            ),
        }
    ];

    return (
        <div>

            {
                state === 'LOADING' &&
                <Loading></Loading>
            }
            {
                state === 'OK' && width >= breakpoint &&
                <div className='container'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{resources.acknowledgement.breadcrumb}</li>
                    </ol>
                    <div className="d-flex w-100">
                        <BCGReactTable
                            data={tableData || []}
                            columns={columns}
                            initialState={{
                                columnVisibility: {
                                    View: true,
                                }
                            }}
                            enableMultiRowSelection={false}
                            enableTopToolbar={true}
                            enableCompactTable={true}
                            enableRowSelection={true}
                            enableStickyHeader={true}
                            enableRowSelectionWithCheckbox={false}
                            enableGlobalFilter={true}
                            tableBodyRowProps={({
                                row
                            }: any) => ({
                                onClick: row.getToggleSelectedHandler(),
                                style: {
                                    cursor: 'pointer'
                                }
                            })}
                            {...props}
                        />
                    </div>
                    <div>
                        <Link to="/">
                            <button className="btn btn-sm btn-primary newRequestButton">{resources.dashboard.acknowledgementsGrid.back}</button>
                        </Link>
                    </div>
                </div>

            }
            {
                state === 'OK' && width < breakpoint &&
                <>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{resources.acknowledgement.breadcrumb}</li>
                    </ol>
                    {
                        tableData.map((request: AcknowledgementCount) => (
                            <div key={request.EMail} className="card gridCard">
                                <div className="card-body">
                                    <div className="d-flex flex-md-row flex-column">
                                        <div className="card-content">
                                            {
                                                <h6>
                                                    <span>
                                                        <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">{request.Count}</span>
                                                        {resources.dashboard.acknowledgementsGrid.title}
                                                    </span>
                                                </h6>
                                            }
                                            <h5 className="card-title">{request.FirstNames} {request.LastNames}</h5>
                                            <p className="card-text">{resources.dashboard.acknowledgementsGrid.eMail}: {request.EMail}</p>
                                            <p className="card-text">{resources.dashboard.acknowledgementsGrid.legalEntity} {request.Company}</p>
                                            <p className="card-text">{resources.dashboard.acknowledgementsGrid.lastAcknowledgement} {moment(request.LastAcknowledgedOn).format('DD/MM/YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    <div>
                        <Link to="/">
                            <button className="btn btn-sm btn-primary newRequestButton">{resources.dashboard.acknowledgementsGrid.back}</button>
                        </Link>
                    </div>
                </>
            }

        </div>
    );
}

export default AcknowledgementsTable;