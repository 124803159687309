import { User } from "../../../Models/User";
import { AuthState } from "../../../custom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import resources from "../../../resources.json";
import RequestsTable from "../../requestsGrid/RequestsTable";
import Analytics from "../../analytics/Analytics";
import React from "react";

export default function AdminView({ authContext: { authState, user }, defaultActiveKey }: {
    defaultActiveKey: any,
    authContext: {
        user: User | null | undefined;
        authState: AuthState | null | undefined;
    }
}) {
    return <Tabs defaultActiveKey={defaultActiveKey} className="mb-3 tabs-mobile">
        <Tab eventKey="pending" title={resources.dashboard.pending}>
            <RequestsTable type="PENDING" authContext={authState} user={user}></RequestsTable>
        </Tab>
        <Tab eventKey="open" title={resources.dashboard.open}>
            <RequestsTable type="OPEN" authContext={authState} user={user}></RequestsTable>
        </Tab>
        <Tab eventKey="closed" title={resources.dashboard.closed}>
            <RequestsTable type="CLOSED" authContext={authState} user={user}></RequestsTable>
        </Tab>
        <Tab eventKey="declined" title={resources.dashboard.declined}>
            <RequestsTable type="DECLINED" authContext={authState} user={user}></RequestsTable>
        </Tab>
        <Tab eventKey="failed" title={resources.dashboard.failed}>
            <RequestsTable type="FAILED" authContext={authState} user={user}></RequestsTable>
        </Tab>
        <Tab eventKey="analytics" title={resources.dashboard.analytics}><Analytics authState={authState}/></Tab>
    </Tabs>;
}