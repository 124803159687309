import axios from 'axios';
import config from '../utils/config';
import { AuthState } from '../custom';
import { Event } from "../Models/Event"
import { User } from '../Models/User';
import { Request } from '../Models/Request';
import { Statistic } from '../Models/Statistic';
import { AcknowledgementManual } from '../Models/Model';
import { Template } from '../Models/Template';
import { Container } from '../Models/Container';
import { AcknowledgementCount } from '../Models/AcknowledgementCount';
import { Image } from '../Models/Image';
import { Templex } from '../Models/Templex';
import axiosRetry from 'axios-retry';

axiosRetry(axios);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

const endpoints = {
    AUDITS: "/audits",
    REQUESTS: "/Requests",
    USERS: "/Users",
    CREATE_REQUEST: "/Requests/AddPartnerRequest",
    SAVE_REQUEST: "/Requests/SaveRequest",
    GET_TEMPLATES: "/Templates",
    REQUEST: "/Requests",
    GET_ACKNOWLEDGEMENT:"/Requests/GetAcknowledgment",
    GET_ACKNOWLEDGEMENT_DOWNLOAD: "/Requests/GetAcknowledgmentForDownload",
    ACKNOWLEDGEMENTs: "/Requests/Acknowledgments",
    FREQUENTLY_ASKED_QUESTIONS: "/FrequentlyAskedQuestions"
};

const httpService = {

    getTemplexes: async (oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/TEMPLEX`;
        return axios.get<Templex[]>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getTemplexImages: async (oktaToken: any, id: string) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/TEMPLEX/${id}`;
        return axios.get<Image[]>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    publishTemplate: async (id: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/${id}/publish`;
        return axios.post<any>(url, null, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    unPublishTemplate: async (id: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/${id}/unpublish`;
        return axios.post<any>(url, null, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },


    getHistory: async (requestID: string, oktaToken?: AuthState | null | undefined) => {

        console.log(oktaToken?.accessToken?.accessToken);

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.AUDITS}/${requestID}/events`;
        return axios.get<Event[]>(url, { headers })
            .then(response => response)
            .catch((err: any) => {
                throw err;
            });
    },

    getMessages: async (area: string, oktaToken?: AuthState | null | undefined) => {

        console.log(oktaToken?.accessToken?.accessToken);

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.AUDITS}/messages/${area}`;
        return axios.get<Container[]>(url, { headers })
            .then(response => response)
            .catch((err: any) => {
                throw err;
            });
    },


    resend: async (endpointUrl?: string, oktaToken?: AuthState | null | undefined) => {

        console.log(endpointUrl);
        if (endpointUrl) {
            const headers = {
                'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
                'x-api-key': `${config.apiKey}`
            };
            const url = `${config.apiUrl}${endpoints.AUDITS}/email/${endpointUrl}`;
            return axios.post(url, null, { headers })
                .then(response => response)
                .catch((err: any) => {
                    throw err;
                });
        }
    },

    downloadFile: async (endpointUrl?: string, oktaToken?: AuthState | null | undefined) => {

        console.log(endpointUrl);
        if (endpointUrl) {
            const headers = {
                'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
                'x-api-key': `${config.apiKey}`
            };
            const url = `${config.apiUrl}/${endpointUrl}`;
            return axios.get(url, { headers })
                .then(response => response)
                .catch((err: any) => {
                    throw err;
                });
        }
    },

    getMe: (oktaToken: AuthState) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.USERS}/me`;
        return axios.get<User>(url, { headers })
            .then(response => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },
    acknowledge: async (token: string | undefined, acknowledgement: AcknowledgementManual,oktaToken: any) => {
        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };
        const url = `${config.apiUrl}/requests/acknowledge/${token}`;
        return axios.post(url, acknowledgement,{ headers })
            .catch((err: any) => {
                throw err;
            });
    },
    getPartners: (token: any) => {
        
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };
        
        const url = `${config.apiUrl}${endpoints.USERS}?role=PARTNER`;
        return axios.get<any>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    addFeedback: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUESTS}/feedback`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    createRequest: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.CREATE_REQUEST}`;
        return axios.post<any>(url, data, { headers }).then(response => { return response.data;}).catch((err: any) => {
            throw err;
        });
    },

    saveRequest: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.SAVE_REQUEST}`;
        return axios.put<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    approveRequest: async (type: string, oktaToken: any) => {
        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let data: any;
        const url = `${config.apiUrl}${endpoints.REQUEST}/approve/${type}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    saveReminder: async (type: string, oktaToken: any) => {
        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let data: any;
        const url = `${config.apiUrl}${endpoints.REQUEST}/SaveReminder/${type}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    declineRequest: async (type: string, oktaToken: any, reason?: string | null | undefined) => {
        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        var data = {Content : reason};
        const url = `${config.apiUrl}${endpoints.REQUEST}/decline/${type}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },
    closeRequest: async (type: string, oktaToken: any, reason?: string | null | undefined) => {
        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let data = {Content : reason};
        const url = `${config.apiUrl}${endpoints.REQUEST}/CloseRequest/${type}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    getRequests: async (oktaToken?: AuthState | null, type?: string, range?: string) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let statusesQuery = '';
        if (type) {
            let splits = type.split(',');
            if (splits.length > 0) {
                statusesQuery = splits.map((status) => `statuses=${status.trim()}`).join('&');
            }
        }

        let rangeQuery = '';
        if (range) {
            rangeQuery = '&range=' + range;
        }

        let query = '?' + statusesQuery + rangeQuery;
        if (query === '?') {
            query = '';
        }

        const url = `${config.apiUrl}${endpoints.REQUESTS}${query}`;
        return axios.get<Request[]>(url, { headers })
            .then(response => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getStatistics: async (oktaToken?: AuthState | null, partner?: number, range?: string, totals? : boolean) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let query = '';
        if (partner && partner > -1) {
            query = `?partner=${partner}`;
        }

        if (range) {
            if (partner && partner > -1) {
                query = query + '&range=' + range;
            }
            else {
                query = '?range=' + range;
            }
        }

        if (totals) {
            query = "?totals=true";
        }

        const url = `${config.apiUrl}${endpoints.REQUESTS}/statistics/${query}`;
        return axios.get<Statistic[]>(url, { headers })
            .then(response => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

   

    getTemplates: async (oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios.get<Template[]>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getDistinctTemplates: async (oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/GetDistinctTemplates`;
        return axios.get<Template[]>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getTemplatebyMasterID: async (oktaToken: any, masterID: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/GetByMasterID/${masterID}`;
        return axios.get<Template[]>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },
    
    getTemplate: async (oktaToken: any, templateID: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}/GetByID/${templateID}`;
        return axios.get<Template>(url, { headers })
            .then(response => response)
            .catch((error: unknown) => {
                throw error;
            });
    },

    updateTemplate: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios.put<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    addTemplate: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_TEMPLATES}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    getRequestData: async (type: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUEST}/${type}`;

        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getRequestsData: async (type: string, token: any, range?: string) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        let rangeQuery = '';
        if (range) {
            rangeQuery = 'range=' + range;
        }

        let query = '?' + rangeQuery;
        if (query === '?') {
            query = '';
        }


        const url = `${config.apiUrl}${endpoints.REQUEST}/Status/${type}${query}`;

        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getAcknowledgement: async (type: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_ACKNOWLEDGEMENT}/${type}`;
        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getAcknowledgementsForDownload: async (type: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.GET_ACKNOWLEDGEMENT_DOWNLOAD}/${type}`;
        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getAcknowledgementsCSV: async (type: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.ACKNOWLEDGEMENTs}/${type}/CSV`;
        return axios.get<Container>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getHistoryZip: async (requestID: string, oktaToken?: AuthState | null | undefined) => {

        console.log(oktaToken?.accessToken?.accessToken);

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.AUDITS}/${requestID}/events/zip`;
        return axios.get<Container>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getHistoryEMail: async (eMailID: string, oktaToken?: AuthState | null | undefined) => {

        console.log(oktaToken?.accessToken?.accessToken);

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.AUDITS}/email/${eMailID}`;
        return axios.get<Container>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getAcknowledgmentCounts: async (oktaToken?: AuthState | null) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken?.accessToken?.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUESTS}/acknowledgements`;
        return axios.get<AcknowledgementCount[]>(url, { headers })
            .then(response => response.data)
            .catch((error: unknown) => {
                throw error;
            });
    },

    getWatchers: async (requestID: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUEST}/${requestID}/watchers`;

        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    deleteWatcher: async (requestID: string, watcherID: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUEST}/${requestID}/watchers/${watcherID}`;

        return axios.delete<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },
    deleteAcknowledgement: async (acknowledgeID: string,requestID: string, token: any) => {
        const headers = {
            'Authorization': `Bearer ${token.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.REQUEST}/${acknowledgeID}/deleteAcknowledge/${requestID}`;

        return axios.delete<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getFrequentlyAskedQuestions: async (role: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}/${role}`;
        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    getFrequentlyAskedQuestionsbyID: async (ID: string, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}/GetbyID/${ID}`;
        return axios.get<any>(url, { headers }).then(response => response).catch((err: any) => {
            throw err;
        });
    },

    createFAQRequest: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}`;
        return axios.post<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

    updateFAQRequest: async (data: any, oktaToken: any) => {

        const headers = {
            'Authorization': `Bearer ${oktaToken.accessToken}`,
            'x-api-key': `${config.apiKey}`
        };

        const url = `${config.apiUrl}${endpoints.FREQUENTLY_ASKED_QUESTIONS}`;
        return axios.put<any>(url, data, { headers }).then(response => response.data).catch((err: any) => {
            throw err;
        });
    },

};

export default httpService;
