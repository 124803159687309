import resources from '../../resources.json';
import React, { useState, useEffect, useRef } from 'react';
import BCGReactTable from '@bcg-web/bcg-react-table';
import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";
import { FAQFormRequest } from '../../Models/Model';
import { Link } from "react-router-dom";
import '../frequentlyAskedQuestions/FAQGrid.css';

type RowSelectionState = Record<string, Boolean>;

const FAQGrid = ({ ...props }) => {

    const navigate = useNavigate();
    const [gridData, setGridData] = useState<FAQFormRequest[]>([]);
    const gridRef = useRef();
    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
    const [isLoading, setisLoading] = useState(true);

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 768;
    React.useEffect(() => {

        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    const onRowSelectionChange = (updaterFunction: (prevState: RowSelectionState) => RowSelectionState) => {

        const newState = updaterFunction(rowSelection);
        setRowSelection(newState);
        console.log(newState);
        var row = gridData[Object.keys(newState)[0] as unknown as number] as any;
        console.log(row);
        navigate("/FAQEditForm/" + row.ID);
    };

    const onCardSelection = (requestId?: string) => {
        navigate("/FAQEditForm/" + requestId);
    }

    useEffect(() => {
        (async () => {

            await httpService.getFrequentlyAskedQuestions(props.role, props.authContext?.accessToken).then(response => { setGridData(response.data) }).then(p => setisLoading(false))
        })();
    }, [props.role]);

    const columns = [
        {
            header: resources.FAQ.dashboard.grid.ID,
            accessorKey: 'ID',
        },
        {
            header: resources.FAQ.dashboard.grid.question,
            accessorKey: 'Question',
        },
        {
            header: resources.FAQ.dashboard.grid.answer,
            accessorKey: 'Answer',
        },
        {
            header: resources.FAQ.dashboard.grid.role,
            accessorKey: 'Role',
        },
        {
            header: resources.FAQ.dashboard.grid.priority,
            accessorKey: 'Priority',
        },

    ];

    return (
        <div className='container'>
            {
                width >= breakpoint &&
                <div className="d-flex w-100">
                    <BCGReactTable
                        ref={gridRef}
                        data={gridData || []}
                        columns={columns}
                        initialState={{
                            columnVisibility:
                            {
                                ID: false
                            }
                        }}
                        enableMultiRowSelection={false}
                        enableTopToolbar={true}
                        enableCompactTable={true}
                        enableRowSelection={true}
                        enableRowSelectionWithCheckbox={false}
                        enableGlobalFilter={false}
                        onRowSelectionChange={onRowSelectionChange}
                        renderTopToolbarCustomActions={() => (
                            <div>
                                {
                                     props.user.Role !== 'VIEWER' &&
                                    <Link to="/FAQForm">
                                        <button type="button" className="btn btn-primary">
                                            <span className="px-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M7 8.99988V15.9999H9V8.99988H16V6.99988H9V-0.00012207H7V6.99988H0V8.99988H7Z" fill="white" />
                                                </svg>
                                            </span>
                                            {resources.FAQ.new}</button>
                                    </Link>
                                }

                            </div>
                        )}
                        tableBodyRowProps={({
                            row
                        }: any) => ({
                            onClick: row.getToggleSelectedHandler(),
                            style: {
                                cursor: 'pointer'
                            }
                        })}
                        state={{
                            showSkeletons: isLoading,
                            isLoading,
                            showProgressBars: isLoading,
                        }}
                        {...props}
                    />

                </div>
            }
            {
                width < breakpoint
                &&
                gridData.map((request: FAQFormRequest) => (
                    <div key={request.ID} className="card gridCard" onClick={() => {
                        onCardSelection(request.ID);
                    }}>
                        <div className="card-body">
                            <div className="d-flex flex-md-row flex-column">
                                <div className="card-content">
                                    <h5 className="card-title">{request.Role}</h5>

                                    <p className="card-text">{resources.FAQ.dashboard.grid.question}: {request.Question}</p>
                                    <p className="card-text">{resources.FAQ.dashboard.grid.answer}: {request.Answer}</p>
                                    <p className="card-text">{resources.FAQ.dashboard.grid.priority}: {request.Priority}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )

};

export default FAQGrid;