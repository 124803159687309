export enum State {
    LOADING = 'LOADING',
    OK = 'OK',
    FAILED = 'FAILED',
    NOTFOUND = 'NOTFOUND',
    UNAUTHORIZED = 'UNAUTHORIZED',
    PENDING = 'PENDING',
    

    FORBIDDEN = 'FORBIDDEN',
    CONFLICT = 'CONFLICT',
    BADREQUEST = 'BADREQUEST',
    INTERNALSERVERERROR = 'INTERNALSERVERERROR',
    SERVICEUNAVAILABLE = 'SERVICEUNAVAILABLE'
}
