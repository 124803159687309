import resources from '../../resources.json';
import BcgLogo from '../../images/bcg-logo-green.png';
import React from 'react';
import './TopNavBar.css';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
import { User } from '../../Models/User';
import Logout from './Logout';

type NavbarProps = {
    user: User | null,
    accessToken?: string
};

const TopNavBar = ({ user }: NavbarProps) => {
    const navigate = useNavigate();
    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 768;
    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    const handleSave = (event: React.MouseEvent<HTMLElement>) => {
        window.location.href = '/nrl/Request';
    };
    return <Navbar className="navbar navbar-expand-md bg-white" id="navbar1" expand="lg">
        <Navbar.Brand href="/nrl" onClick={() => navigate('/')}>
            <img src={BcgLogo} alt="BCG Logo" width="50" height="25" />
            <span className="mx-3">|</span>
            {resources.navbar.title}
        </Navbar.Brand>
        <div><Link hidden={width >= breakpoint} to="/viewFrequentlyAskedQuestions"><button className="btn btn-text me-2">FAQ</button></Link></div>
        <div className='hamburgerMenu'>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
            <Nav className="navbar-nav ml-auto" >
            </Nav>


            <Nav className="d-flex">
                {
                    user?.Role !== 'ADMIN' && user?.Role !== 'VIEWER' &&
                    <>
                        <Nav.Link hidden={width >= breakpoint} className='rightLinks hamburgerLinks' onClick={(e) => handleSave(e)}>{resources.navbar.new}</Nav.Link>
                        <Nav.Link hidden={width >= breakpoint} className='rightLinks hamburgerLinks' href="/nrl/viewAllAcknowledgments">{resources.navbar.viewallacknowledgements}</Nav.Link>                   
                    </>
                }
                {
                    (user?.Role === 'ADMIN' || user?.Role === 'VIEWER') &&
                    <>
                        <Nav.Link hidden={width >= breakpoint} className='rightLinks hamburgerLinks' href="/nrl/frequentlyAskedQuestions">{resources.navbar.faq}</Nav.Link>
                        <Nav.Link hidden={width >= breakpoint} className='rightLinks hamburgerLinks' href="/nrl/templates">{resources.navbar.templates}</Nav.Link>
                        <Nav.Link hidden={width >= breakpoint} className='rightLinks hamburgerLinks' href="/nrl/viewAllAcknowledgments">{resources.navbar.viewallacknowledgements}</Nav.Link>
                    </>
                }
                {user && <div className='userInfo'>
                    
                    <p><Link to="/viewFrequentlyAskedQuestions"><button className="btn btn-text me-2">FAQ</button></Link> {user?.Name}, {user?.Role === 'ADMIN' ? resources.navbar.admin : (user?.Role === 'COORDINATOR' ? resources.navbar.coordinator : (user?.Role === 'PARTNER' ? resources.navbar.partner : resources.navbar.viewer))}</p>
                </div>}
            </Nav>

        </Navbar.Collapse>
    </Navbar>;;
};

export default TopNavBar;