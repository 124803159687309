import React, { useState, useEffect } from 'react';
import resources from '../../resources.json';
import { useFieldArray, useFormState, useFormContext } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import { isIE, browserName } from 'react-device-detect';
import { Request, Watcher } from "../../Models/Model"
import './Watchers.css'

import httpService from "../../services/httpService";
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import { useNavigate } from 'react-router-dom';


type Props = {
    authContext?: AuthState | null,
    enabled: boolean
    isNew: boolean,
    requestID: string,
    Watchers: Watcher[],
    enableButton: any,
    enableSave: any
};

type ToDeleteType = {
    deleteIndex: number,
    deleteId?: string
}

const Watchers = (props: Props) => {

    const { register, control } = useFormContext<Request>() // retrieve all hook methods
    const { errors } = useFormState({
        control
    });

    const { fields, append, remove, replace } = useFieldArray<Request>({
        control,
        name: "Watchers", // Name of your array field
    });

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [toDelete, setToDelete] = useState<ToDeleteType>();

    const isFormInvalid = Object.keys(errors).length > 0;

    useEffect(() => {
        replace(props.Watchers);
        setIsLoading(false);
    }, [props.Watchers, replace]);

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const Delete = async () => {
        setDisableButtons(true);
        await httpService.deleteWatcher(props.requestID, toDelete?.deleteId ? toDelete.deleteId : "", props.authContext?.accessToken).catch(err => {
            console.log(err.response.status);
            if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                navigate("/unavailable");
            }
            else if (err.response.status === 500) {
                navigate("/error");
            }
            else if (err.response.status === 403 || err.response.status === 401) {
                navigate("/unauthorised");
            }
            else {
                navigate("/unavailable");
            }
        });
        remove(toDelete?.deleteIndex);
        setIsOpen(false);
        setDisableButtons(false);
    }

    return (
        <div>
            {
                isLoading &&
                <Loading></Loading>
            }
            {
                !isLoading &&
                <div className={`isFormInvalid ? 'was-validated'}`}>
                    <div className='row titleWatchers'>
                        <div className="col-md"> <p>
                            {resources.request.watcher.title}
                        </p></div>
                        <div className="col-md">
                            {
                                props.enabled &&
                                <div className='addWatchers'>
                                    <div onClick={() => { append({ Name: "", EMail: "", IsNew: true }); props.enableButton(true); props.enableSave(false) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z"></path>
                                        </svg>
                                        <p>Add Watcher</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {fields.map((item, index) => (
                        <div key={item.id}>
                            <div className="row watcherRow">
                                <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label className="form-label">{resources.request.watcher.firstName.label}</label>
                                        <input disabled={!props.enabled || !item.IsNew} className={`form-control ${errors.Watchers?.[index]?.Name ? 'is-invalid' : ''}`} type="text"
                                            defaultValue={item.id}
                                            placeholder={resources.request.watcher.firstName.placeholder}
                                            id="FirstNames"
                                            {...register(`Watchers.${index}.Name` as const, {
                                                required: resources.request.watcher.firstName.required,
                                                validate: { isEmpty: e => isNotEmpty(e) || resources.request.watcher.firstName.validation }
                                            })}
                                        />
                                        {errors.Watchers?.[index]?.Name && <div className="invalid-feedback"><span>{resources.request.watcher.firstName.required}</span></div>}
                                    </div>
                                </div>
                                {/* <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label className="form-label">{resources.request.watcher.lastName.label}</label>
                                        <input className={`form-control ${errors.Watchers?.[index]?.LastName ? 'is-invalid' : ''}`} type="text"
                                            defaultValue={item.id}
                                            placeholder={resources.request.watcher.lastName.placeholder} id="LastNames"
                                            {...register(`Watchers.${index}.LastName` as const, {
                                                required: resources.request.watcher.lastName.required,
                                                validate: { isEmpty: e => isNotEmpty(e) || resources.request.watcher.lastName.validation }
                                            })}
                                        />
                                        {errors.Watchers?.[index]?.LastName && <div className="invalid-feedback"><span>{resources.request.watcher.lastName.required}</span></div>}
                                    </div>
                                    <button type="button" onClick={() => remove(index)}>
                                        Remove
                                    </button>
                                </div> */}
                                <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label className="form-label">{resources.request.watcher.eMail.label}</label>
                                        <input disabled={!props.enabled || !item.IsNew} className={`form-control ${errors.Watchers?.[index]?.EMail ? 'is-invalid' : ''}`}
                                            defaultValue={item.id}
                                            type="text" placeholder={resources.request.watcher.eMail.placeholder} id="Email"
                                            {...register(`Watchers.${index}.EMail` as const, {
                                                required: resources.request.watcher.eMail.required,
                                                pattern: {
                                                    value: /^\S+@\S+\.\S+$/,
                                                    message: resources.request.watcher.eMail.validation,
                                                }
                                            })} />
                                        {errors.Watchers?.[index]?.EMail && <div className="invalid-feedback"><span>{resources.request.watcher.eMail.required}</span></div>}
                                    </div>
                                </div>
                                <div className='col-md col col-small watcherActions'>
                                    {!item.IsNew &&
                                        props.enabled &&
                                        // <button type="button" onClick={() => Delete(index, item.ID)}>
                                        //     Delete
                                        // </button>
                                        <div className='removeWatchers'>
                                            <div onClick={() => {
                                                setToDelete({ deleteIndex: index, deleteId: item.ID })
                                                setIsOpen(true);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{
                                            float: "right"
                                        }} className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg>
                                            </div>
                                            <Modal show={isOpen} onHide={() => setIsOpen(false)}>
                                                <Modal.Header>Delete Confirmation</Modal.Header>
                                                <Modal.Body>Are you sure you want to delete the watcher information?</Modal.Body>
                                                <Modal.Footer>
                                                    <div className="row button-row">
                                                        <div className="col-md d-flex flex-row-reverse">
                                                            <button type="button" disabled={disableButtons} onClick={() => Delete()} className="btn btn-sm btn-primary me-2">Yes</button>
                                                            <button type="button" disabled={disableButtons} onClick={() => setIsOpen(false)} className="btn btn-sm btn-primary me-2">Cancel</button>
                                                        </div>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    }
                                    {item.IsNew &&
                                        props.enabled &&
                                        // <button type="button" onClick={() => remove(index)}>
                                        //     Discard
                                        // </button>
                                        <div className='removeWatchers'>
                                            <div onClick={() => remove(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                </svg>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-md">
                                    <label className="form-label">{resources.request.watcher.eMail.label}</label>
                                    <input className={`form-control ${errors.Watchers?.[index]?.Email ? 'is-invalid' : ''}`}
                                        defaultValue={item.id}
                                        type="text" placeholder={resources.request.watcher.eMail.placeholder} id="Email"
                                        {...register(`Watchers.${index}.Email` as const, {
                                            required: resources.request.watcher.eMail.required,
                                            pattern: {
                                                value: /^\S+@\S+\.\S+$/,
                                                message: resources.request.watcher.eMail.validation,
                                            }
                                        })} />
                                    {errors.Watchers?.[index]?.Email && <div className="invalid-feedback"><span>{resources.request.watcher.eMail.required}</span></div>}
                                </div> */}
                                <div className="col-md">

                                </div>
                            </div>
                        </div>
                    ))}
                    {/* {
                        !props.isNew &&
                        props.enabled &&
                        <div className="row">
                            <div className="col-md">
                            </div>
                            <div className="col-md">
                                <div className='buttonRow'>
                                    <button type="button" onClick={() => alert(props.isNew)} className="btn btn-sm btn-primary me-2">{resources.request.watcher.save}</button>
                                </div>
                            </div>
                        </div>
                    } */}
                </div>

            }
        </div>
    );
}

export default Watchers;
