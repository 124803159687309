import httpService from "../../services/httpService";
import {useEffect, useState} from "react";
import {Template} from "../../Models/Model";
import {AuthState} from "../../custom";
import {useNavigate} from "react-router-dom";

interface UseTemplatesResult {
    templates: Template[];
}

export default function useTemplates(authContext?: AuthState | null, onLoadingChange?: ((value: boolean) => void) | undefined): UseTemplatesResult {
    const navigate = useNavigate();
    const [templates, setTemplates] = useState<Template[]>([]);

    function setLoading(value: boolean) {
        if (onLoadingChange) onLoadingChange(value);
    }

    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true);
            try {
                const response = await httpService.getTemplates(authContext?.accessToken)
                setTemplates(response.data);
            } catch (error: any) {
                console.log(error.response.status);
                if (error.response.status === 404 || error.response.status === 400 || error.response.status === 405) {
                    navigate("/unavailable");
                } else if (error.response.status === 500) {
                    navigate("/error");
                } else if (error.response.status === 403 || error.response.status === 401) {
                    navigate("/unauthorised");
                } else {
                    navigate("/unavailable");
                }
            } finally {
                setLoading(false);
            }
        }
        fetchTemplates();
    }, [authContext, navigate]);

    return {templates};
}