import resources from '../../resources.json';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './../resolveRequest/RequestDecline.css';

import httpService from "../../services/httpService";
import { UserInfo } from "../../custom";
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import { Link } from "react-router-dom";

type PageProps = {
    userInfo?: UserInfo | null,
    authContext?: AuthState | null,
};

const RequestDecline = ({ userInfo, authContext }: PageProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [isDeclined, setIsDeclined] = useState(false);
    const [isAlredyApproved, setIsAlreadyApproved] = useState(false);
    const [isAlreadyDeclined, setIsAlreadyDeclined] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        declineRequest();
    }, []);

    const declineRequest = () => {
        (async () => {
            if (isLoading && params.token != null) {
                await httpService.declineRequest(params.token, authContext?.accessToken).then(response => {
                    if (response.toLowerCase() === "request already approved") {
                        setIsAlreadyApproved(true);
                    }
                    else if (response.toLowerCase() === "request already declined") {
                        setIsAlreadyDeclined(true);
                    }
                    else {
                        setIsDeclined(true)
                    }

                }).then(p => setIsLoading(false)).catch(err => {

                   
                    if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                        navigate("/unavailable");
                    }
                    else if (err.response.status === 500) {
                        navigate("/error");
                    }
                    else if (err.response.status === 304) {
                        
                        setIsAlreadyApproved(true);
                        setIsLoading(false);
                    }
                    else if (err.response.status === 409) {
                        console.log(err);
                        setIsAlreadyDeclined(true); 
                        setIsLoading(false);
                    }
                    else if (err.response.status === 403 || err.response.status === 401) {
                        navigate("/unauthorised");
                    }
                    else {
                        navigate("/unavailable");
                    }
                });
            }
        })();
    }


    return (
        <div>
            {
                isLoading &&
                <Loading></Loading>
            }
            {
                !isLoading && isDeclined &&
                <div className="container">
                    <div>
                        <div>
                            <p className='title'>{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z" fill="green" /></svg>
                                <div className='messageDetails'>
                                    {resources.requestDecline.message}
                                </div>

                            </div>
                            <Link to="/">
                                <button type="button" className="btn btn-primary">{resources.requestApprove.dashboard}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            {
                !isLoading && isAlredyApproved &&
                <div className="container">
                    <div>
                        <div>
                            <p className='title'>{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z" fill="green" /></svg>
                                <div className='messageDetails'>
                                    {resources.requestAlreadyApproved.message}
                                </div>

                            </div>
                            <Link to="/">
                                <button type="button" className="btn btn-primary">{resources.requestApprove.dashboard}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            {
                !isLoading && isAlreadyDeclined &&
                <div className="container">
                    <div>
                        <div>
                            <p className='title'>{resources.confirmationText.successTitle}</p>
                            <p>{resources.confirmationText.successSubTitle}</p>
                        </div>
                        <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z" fill="green" /></svg>
                                <div className='messageDetails'>
                                    {resources.requestAlreadyDeclined.message}
                                </div>

                            </div>
                            <Link to="/">
                                <button type="button" className="btn btn-primary">{resources.requestApprove.dashboard}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RequestDecline;
