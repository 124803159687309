import './History.css'
import resources from '../../resources.json';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Event } from "../../Models/Event"
import { State } from "../../Models/State"
import httpService from "../../services/httpService";
import { AuthState } from '../../custom';
import Loading from '../routeHandler/Loading';
import HistoryEvent from './HistoryEvent';
import Unauthorized from '../notices/Unauthorised';
import config from '../../utils/config';
import NotFound from '../notFound/NotFound';

type Props = {
    authContext?: AuthState | null
};

const History = ({ authContext }: Props) => {

    const params = useParams();
    const navigate = useNavigate();

    const [requestID, setRequestID] = useState('');
    const [events, setEvents] = useState<Event[]>([]);

    const [state, setState] = useState<State>(State.PENDING);

    const history = '/nrl/Request/' + requestID;
    const download = config.apiUrl + '/Audits/' + requestID + '/events/zip';

    const [zip, setZip] = useState('');
    const [zipName, setZipName] = useState('');

    useEffect(() => {
        (async () => {
            if (params.requestID) {
                setRequestID(params.requestID);
                await httpService.getHistory(params.requestID!, authContext)
                    .then(response => { setEvents(response.data); })
                    .then(() => setState(State.OK))
                    .catch(err => {
                        if (err.response.status === 404 || err.response.status === 400 || err.response.status === 405) {
                            setState(State.NOTFOUND);
                        }
                        else if (err.response.status === 500) {
                            setState(State.INTERNALSERVERERROR);
                        }
                        else if (err.response.status === 403 || err.response.status === 401) {
                            setState(State.UNAUTHORIZED);
                        }
                        else {
                            setState(State.FAILED);
                        }
                    });

                await httpService.getHistoryZip(params.requestID!, authContext)
                    .then(response => {
                        var base64str = response.data.Content;
                        if (base64str) {
                            setZip("data:application/zip;base64," + response.data.Content);
                            setZipName(params.requestID + '.zip');
                        }
                    })
                    .catch(() => {
                        setState(State.FAILED);
                    });
            }
        })();
    }, [authContext, authContext?.accessToken, navigate, params.requestID]);

    return (
        <div>
            {
                state === State.UNAUTHORIZED && <Loading></Loading>
            }
            {
                state === State.UNAUTHORIZED && <Unauthorized />
            }
            {
                state === State.NOTFOUND && <NotFound />
            }
            {
                state === State.OK &&
                <div className="history-container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/nrl">{resources.request.previousCrumb}</a>
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                            <a href={history}>{requestID}</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{resources.history.breadcrumb}</li>
                    </ol>

                    <h4 className="faqTitle">{resources.history.title}</h4>
                    {
                        events?.map((item: Event) => {
                            return (
                                <div className="row" key={item.ID}>
                                    <div className="col-2 mt-2">
                                        <p>{item.Timestamp}</p>
                                        <p>{item.User.Name}</p>
                                        <p>{item.User.Role}</p>
                                    </div>
                                    <div className="col-10">
                                        <HistoryEvent event={item} authContext={authContext} tab={0} ></HistoryEvent>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="history-spacer">
                        <Link to={zip} target="_blank" download={zipName} className="btn btn-primary">Download</Link>
                    </div>
                </div>
            }
        </div>
    )
};

export default History;