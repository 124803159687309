import React, { useEffect, useState } from 'react';
import { AuthState, UserInfo } from "../../custom";
import httpService from '../../services/httpService';
import { Templex, TemplexVersion } from '../../Models/Templex';
import { Image } from '../../Models/Image';
import { State } from '../../Models/State';
import Loading from '../routeHandler/Loading';
import UnauthorisedPage from '../confirmation-error/UnauthorisedPage';
import UnavailablePage from '../confirmation-error/UnavailablePage';
import { User } from '../../Models/User';
import moment from 'moment';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import config from '../../utils/config';
import { Link, useNavigate } from "react-router-dom";

import Carousel from 'react-bootstrap/Carousel';

type TemplatesProps = {
    authContext?: AuthState | null,
    userInfo?: UserInfo | null,
    user?: User | null
};

const Templates = ({ userInfo, authContext, user }: TemplatesProps) => {
    const navigate = useNavigate();
    const [state, setState] = useState(State.LOADING);

    const [templates, setTemplates] = useState<Templex[]>();
    const [template, setTemplate] = useState<Templex>();
    const [versions, setVersions] = useState<TemplexVersion[]>();
    const [version, setVersion] = useState<TemplexVersion>();
    const [pdf, setPdf] = useState('');

    const [images, setImages] = useState<Image[]>();

    const changeTemplate = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selected = templates?.find(id => id.ID === e.target.value);
        setTemplate(selected);
        setVersions(selected?.Versions);
    }

    const [index, setIndex] = useState(0);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [prevEnabled, setPrevEnabled] = useState(false);

    const next = () => {
        if (images?.length !== undefined) {
            let t = index + 1;
            if (t < images.length) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < (images.length - 1));
            }
        }
    };

    const download = () => {
        if (images?.length !== undefined) {
            let t = index + 1;
            if (t < images.length) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < (images.length - 1));
            }
        }
    };

    const previous = () => {
        if (images?.length !== undefined) {
            let t = index - 1;
            if (t >= 0) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < (images.length - 1));
            }
        }
    };

    const first = () => {
        if (images?.length !== undefined) {
            let t = 0;
            if (t < images.length) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < (images.length - 1));
            }
        }
    };

    const last = () => {
        if (images?.length !== undefined) {
            let t = images.length - 1;
            if (t >= 0) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < (images.length - 1));
            }
        }
    };


    useEffect(() => {
        (async () => {
            await getTemplexes();
        })();
    }, [authContext]);

    const getTemplexes = async () => {
        (async () => {
            await httpService.getTemplexes(authContext?.accessToken)
                .then(response => {
                    setState(State.OK);
                    setTemplates(response.data);

                    if (templateName === '') {
                        setTemplate(response.data[0]);
                        if (response.data[0]) {
                            setVersions(response.data[0].Versions)
                        }
                    } else {
                        const selected = response.data.find((element) => {
                            return element.Name === templateName;
                        });
                        setTemplate(selected);
                        setVersions(selected?.Versions);
                        setTemplateName('');
                    }
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }

    const updateVersions = async (id: string) => {
        (async () => {
            await httpService.getTemplexes(authContext?.accessToken)
                .then(response => {
                    const selected = response.data.find((element) => {
                        return element.ID === id;
                    });
                    if (template) {
                        template.Versions = selected?.Versions;
                        setVersions(template.Versions);
                    }
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }


    const view = async (id: string) => {
        setIndex(0);
        setImages([]);
        setPdf('');

        if (template && template.Versions) {
            const selected = template?.Versions.find((element) => {
                return element.ID === id;
            });
            setVersion(selected);
        }

        await httpService.getTemplexImages(authContext?.accessToken, id)
            .then(response => {
                setImages(response.data);
                setNextEnabled(true);
            })
            .catch(() => {
                setState(State.FAILED);
            });

        await httpService.getTemplate(authContext?.accessToken, id)
            .then(response => {
                var base64str = response.data.Content;
                if (base64str) {
                    setPdf("data:application/pdf;base64," + response.data.Content);
                }
            })
            .catch(() => {
                setState(State.FAILED);
            });
    }

    const publish = async (id: any) => {

        if (template && template.Versions) {

            const selected = template?.Versions.find((element) => {
                return element.ID === id;
            });

            if (selected?.IsPublished) {
                await httpService.unPublishTemplate(id, authContext?.accessToken)
                    .then(() => {
                        setFile(undefined);
                    });
            } else {
                await httpService.publishTemplate(id, authContext?.accessToken)
                    .then(() => {
                        setFile(undefined);
                    });
            }

            await updateVersions(template.ID);
        }
    }

    const [templateName, setTemplateName] = useState('');
    const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(e.target.value.trim());
    }

    const [file, setFile] = useState<File>();
    const [result, setResult] = useState("");

    const fileToBase64 = (file: File, cb: any) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const handleFileChange = (e: any) => {
        if (e.target.files) {
            setFile(e.target.files[0]);

            if (templateName === '') {
                setTemplateName(e.target.files[0].name.split('.').slice(0, -1).join('.'));
            }

            fileToBase64(e.target.files[0], (err: any, base64String: string) => {
                if (base64String) {
                    setResult(base64String.replace("data:application/pdf;base64,", ""));
                }
                else {
                    console.log(err);
                }
            })
        }
    };

    const handleOnClick = (e: any) => {
        if (e) {
            e.target.value = "";
            setResult("");
            setFile(undefined);
        }

    }

    const [isNewVersion, setIsNewVersion] = useState(false);

    const onAddTemplate = async () => {
        const newRequest: Object = {
            Name: templateName,
            Content: result
        }

        await httpService.addTemplate(newRequest, authContext?.accessToken)
            .then((response) => {
                setFile(undefined);
                getTemplexes();

                const selected = templates?.find(id => id.ID === response.ID);
                setTemplate(selected);

                console.log(response);
                console.log(selected);

                setVersions(selected?.Versions);
            });
    }

    return (
        <>
            {
                state === 'LOADING' &&
                <Loading></Loading>
            }
            {
                state === 'UNAUTHORIZED' &&
                <UnauthorisedPage />
            }
            {
                state === 'FAILED' &&
                <UnavailablePage />
            }
            {
                state === 'OK' && (user?.Role !== 'ADMIN' && user?.Role !== 'VIEWER') &&
                <UnauthorisedPage />
            }
            {
                state === 'OK' && (user?.Role === 'ADMIN' || user?.Role === 'VIEWER') &&

                <>
                    <div className="container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/nrl">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Templates</li>
                        </ol>
                        <div className="form-group mb-3">
                            <div className="d-flex justify-content-between">
                                <label className="form-label-faq">Templates</label>
                                {
                                    user?.Role === 'ADMIN' &&
                                    <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#add" onClick={() => setIsNewVersion(false)}>New Template</button>
                                }
                            </div>
                            <select className="form-select" value={template?.ID} onChange={changeTemplate}>
                                {
                                    templates?.map((template) => {
                                        return (
                                            <option key={template.ID} value={template.ID}>{template.Name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        {
                            versions?.map((version) => {
                                return (
                                    <div key={version.ID} className="card mb-3">
                                        <div className="row g-0">
                                            <div id={version.ID} className="col-md-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">Version: {version.Version ?? 'Not available'}</h5>
                                                    <p className="card-title">Last updated by: <strong>{version.CreatedBy}</strong></p>
                                                    <p className="card-title">Last updated on: <strong>{moment(version.CreatedOn).format('MMM DD, YYYY')}</strong> at <strong>{moment(version.CreatedOn).format('hh:mm')}</strong></p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="m-4">
                                                    <div className="card-tags">
                                                        {
                                                            user?.Role === 'ADMIN' &&
                                                            <span onClick={() => publish(version.ID)} className={'chip m-2 toggle ' + (version.IsPublished ? 'active' : '')}>{version.IsPublished ? 'Published' : 'Not published'}</span>
                                                        }
                                                        {
                                                            user?.Role !== 'ADMIN' &&
                                                            <span style={{ cursor: 'default' }} className={'chip m-2 toggle ' + (version.IsPublished ? 'active' : '')}>{version.IsPublished ? 'Published' : 'Not published'}</span>
                                                        }
                                                        <span className="m-2 chip" style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#view" onClick={() => view(version.ID)}>View</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            user?.Role === 'ADMIN' &&

                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#add" onClick={() => {
                                    setIsNewVersion(true);
                                    setTemplateName(template?.Name ?? '');
                                }
                                }>New Version</button>
                            </div>
                        }
                    </div>

                    <div id="view" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                        <h4 className="">{template?.Name}</h4>
                                        <h5 className="">Version: {version?.Version ?? "Not Available"}</h5>
                                </div>
                                <div className="modal-body">
                                    {
                                        images?.length === 0 && <Loading />
                                    }
                                    {(images?.length ?? 0) > 0 &&
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <button disabled={!prevEnabled} onClick={first}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-skip-start-fill" viewBox="0 0 16 16">
                                                        <path d="M4 4a.5.5 0 0 1 1 0v3.248l6.267-3.636c.54-.313 1.232.066 1.232.696v7.384c0 .63-.692 1.01-1.232.697L5 8.753V12a.5.5 0 0 1-1 0z" />
                                                    </svg>
                                                </button>
                                                <button disabled={!prevEnabled} onClick={previous}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                                    </svg>
                                                </button>
                                                <label className="mt-2" style={{ fontSize: 16 }}>Page {index + 1} of {images?.length}</label>
                                                <button disabled={!nextEnabled} onClick={next}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                </button>
                                                <button disabled={!nextEnabled} onClick={last}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-skip-end-fill" viewBox="0 0 16 16">
                                                        <path d="M12.5 4a.5.5 0 0 0-1 0v3.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308v7.384c0 .63.692 1.01 1.233.697L11.5 8.753V12a.5.5 0 0 0 1 0z" />
                                                    </svg>
                                                </button>
                                                <span className="m-2 chip"><Link style={{ textDecoration: 'none' }} to={pdf} target="_blank" download={template?.Name}>Download</Link></span>
                                            </div>
                                            <Carousel activeIndex={index} indicators={false}>
                                                {
                                                    images?.map((image) => {
                                                        return (
                                                            <Carousel.Item key={image.Page}>
                                                                <TransformWrapper>
                                                                    <TransformComponent>
                                                                        <img id={'#' + image.Page} className="d-block w-100" alt={'Page' + image.Page} src={image.Content}></img>
                                                                    </TransformComponent>
                                                                </TransformWrapper>
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-text me-1" data-bs-dismiss="modal" aria-hidden="true">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="add" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Add new {isNewVersion ? 'version' : 'template'}</h4>
                                </div>
                                <div className="modal-body">
                                    <div className='container'>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Template Name</label>
                                            <input disabled={isNewVersion} className='form-control' type="text" placeholder="Template Name" id="TemplateName" defaultValue={templateName} onChange={handleTemplateNameChange}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Template*:</label><br />
                                            <label htmlFor="formFile1" className="btn btn-sm btn-primary">Choose File</label>
                                            <input className="d-none form-control" type="file" id="formFile1" onChange={handleFileChange} onClick={handleOnClick} accept='application/pdf' />
                                            <div>
                                                {file &&
                                                    <div className="file">
                                                        <div className="details success">
                                                            <i className="file-icon"></i><span className="file-name">{file.name}</span>
                                                        </div>
                                                        <i className="remove" onClick={() => {
                                                            if (templateName.split('.').slice(0, -1).join('.') === file.name.split('.').slice(0, -1).join('.')) {
                                                                setTemplateName('');
                                                            }
                                                            setFile(undefined);
                                                        }}></i>
                                                    </div>
                                                }
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-text me-1" data-bs-dismiss="modal" aria-hidden="true" onClick={() => {
                                        setTemplateName('');
                                        setFile(undefined);
                                    }}>Cancel</button>
                                    <button type="button" disabled={file?.name === undefined || '' ? true: false} className="btn btn-text me-1" data-bs-dismiss="modal" aria-hidden="true" onClick={onAddTemplate}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Templates;