import '../../index.css'
import FAQItem from '../frequentlyAskedQuestions/FAQItem';
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import './FAQ.css'
import { Link } from "react-router-dom";
import { FrequentlyAskedQuestions } from '../../Models/Model';
import { User } from '../../Models/User';
import { AuthState } from '../../custom';
import { State } from '../../Models/State';
import Loading from '../routeHandler/Loading';
import UnauthorisedPage from '../confirmation-error/UnauthorisedPage';
import UnavailablePage from '../confirmation-error/UnavailablePage';

type PageProps = {
  authContext?: AuthState | null
  user?: User | null
};

const FAQ = ({ authContext, user }: PageProps) => {
  const navigate = useNavigate();
  const [state, setState] = useState(State.LOADING);
  const [faqData, setFAQData] = useState<FrequentlyAskedQuestions[]>([]);

  useEffect(() => {
    (async () => {
      await httpService.getFrequentlyAskedQuestions(user?.Role, authContext?.accessToken).then(response => {
        setState(State.OK);
        setFAQData(response.data)
      }).catch(() => {
        setState(State.FAILED);
      });
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  return (
    <div className='container'>
      {
        state === 'LOADING' &&
        <Loading></Loading>
      }
      {
        state === 'UNAUTHORIZED' &&
        <UnauthorisedPage />
      }
      {
        state === 'FAILED' &&
        <UnavailablePage />
      }
      {
        state === 'OK' &&
        <>
          <h4 className="faqTitle">Frequently Asked Questions</h4>
          {
            faqData && faqData.map((item) => {
              return (
                <div className="accordion" key={item.Question}>
                  <FAQItem question={item.Question} answer={item.Answer} defaultKeyValue={item.Priority === 1 ? "0" : "1"} />
                </div>
              )
            })
          }
          <div>
            <Link to="/">
              <button className="btn btn-sm btn-primary newRequestButton">Back</button>
            </Link>
          </div>
        </>
      }
    </div>
  );
}

export default FAQ;
