import BCGReactTable from '@bcg-web/bcg-react-table';
import '@bcg-web/bcg-react-table/dist/bcg-react-table.css';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { RequestLine } from '../../Models/Model';
import { Range } from '../../Models/Range';
import { State } from '../../Models/State';
import resources from '../../resources.json';
import httpService from "../../services/httpService";
import UnavailablePage from '../confirmation-error/UnavailablePage';
import Loading from '../routeHandler/Loading';
import './RequestsGrid.css';

type RowSelectionState = Record<string, Boolean>;
const RequestGrid = ({ ...props }) => {

  const navigate = useNavigate();
  const [gridData, setGridData] = useState<RequestLine[]>([]);
  const gridRef = useRef();
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [isLoading, setisLoading] = useState(true);
  const [isFetching, setisFetching] = useState(true);
  const [state, setState] = useState(State.LOADING);
  const [range, setRange] = useState(Range.ALL);
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 768;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const onRowSelectionChange = (updaterFunction: (prevState: RowSelectionState) => RowSelectionState) => {


    const newState = updaterFunction(rowSelection);

    setRowSelection(newState);
    const selectedRows = gridRef;
    var row = gridData[Object.keys(newState)[0] as unknown as number] as any;


    navigate("/Request/" + row.ID);
  };

  const onCardSelection = (requestId?: string) => {
    navigate("/Request/" + requestId);
  }

  useEffect(() => {
    (async () => {
      await httpService.getRequestsData(props.type, props.authContext?.accessToken, range)
        .then(response => {
          setState(State.OK);
          setGridData(response.data);
        }).then(p => setisLoading(false)).then(p => setisFetching(false))
        .catch(() => {
          setState(State.FAILED);
        });
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [props.authContext, props.type, props.user?.Role, range]);

  const changeRange = (event: any) => {
    if (event != null && event.target != null && event.target.value != null && event.target.value !== undefined)
      setRange(event.target.value);
  };

  const columns = [
    {
      header: resources.dashboard.grid.requestID,
      accessorKey: 'ID',
      id: "ID",
      cell: ({ cell }: any) => (

        <div>
          <span>{cell.getValue()}</span>
          {cell.row.original.OfflineValue === "True" &&
            <svg className="newColor" style={{
              float: "right"
            }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27l19.72 19.73zM19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 0 0-2-2a2 2 0 0 0-2 2v.29c-.61.18-1.2.45-1.74.8zM12 23a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2" /></svg>
          }
        </div>

      ),
    },
    {
      header: 'OfflineValue',
      id: "OfflineValue",
      accessorKey: "OfflineValue",
    },
    {
      header: resources.dashboard.grid.project,
      accessorKey: 'ProjectName',
    },
    {
      header: resources.dashboard.grid.partner,
      accessorKey: 'Partner',
    },
    {
      header: resources.dashboard.grid.coordinator,
      accessorKey: 'TransactionCoordinator',
    },
    {
      header: resources.dashboard.grid.createdOn,
      accessorKey: 'Opened',
      cell: ({ cell }: any) => (
        <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      header: resources.dashboard.grid.modifiedOn,
      accessorKey: 'Modified',
      cell: ({ cell }: any) => (
        <span>{moment(cell.getValue()).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      header: resources.dashboard.grid.acknowledgements,
      accessorKey: "AcknowledgementCount",
      cell: ({ cell }: any) => (
        <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">{cell.getValue()}</span>
      ),
    },
    {
      header: resources.dashboard.grid.status,
      accessorKey: "Status",
    },
  ];

  return (
    <div className='container'>
      {
        state === 'LOADING' &&
        <Loading></Loading>
      }
      {
        state === 'FAILED' &&
        <UnavailablePage></UnavailablePage>
      }
      {
        state === 'OK' && width >= breakpoint &&
        <div className="d-flex w-100">
          <BCGReactTable
            ref={gridRef}
            data={gridData || []}
            columns={columns}
            initialState={{
              columnVisibility:
              {
                OfflineValue: false,
                Partner: props.isAdministrator,
                AcknowledgementCount: props.showInfoColumns,
                View: props.showInfoColumns,
                Status: props.showStatusColumns,
              }
            }}
            enableMultiRowSelection={false}
            enableTopToolbar={true}
            enableCompactTable={true}
            enableRowSelection={true}
            enableRowSelectionWithCheckbox={false}
            enableGlobalFilter={true}
            onRowSelectionChange={onRowSelectionChange}
            renderTopToolbarCustomActions={() => (
                <div className="d-flex">
                    <select className="form-select" defaultValue={Range.ALL} id="Range" onChange={changeRange}>
                  <option key={Range.ALL} defaultValue={Range.ALL} value={Range.ALL}>All Requests</option>
                  <option key={Range.ONEWEEK} defaultValue={Range.ONEWEEK} value={Range.ONEWEEK}>From the last week</option>
                  <option key={Range.TWOWEEK} defaultValue={Range.TWOWEEK} value={Range.TWOWEEK}>From the last two weeks</option>
                  <option key={Range.ONEMONTH} defaultValue={Range.ONEMONTH} value={Range.ONEMONTH}>From the last month</option>
                  <option key={Range.THREEMONTH} defaultValue={Range.THREEMONTH} value={Range.THREEMONTH}>From the last three months</option>
                  <option key={Range.YEARTODATE} defaultValue={Range.YEARTODATE} value={Range.YEARTODATE}>From this year</option>
                </select >
              </div>
            )}
            tableBodyRowProps={({
              row
            }: any) => ({
              onClick: row.getToggleSelectedHandler(),
              style: {
                cursor: 'pointer'
              }
            })}
            state={{
              showSkeletons: isLoading,
              isLoading,
              showProgressBars: isLoading,
            }}
            {...props}
          />

        </div>
      }
      {
        width < breakpoint
        &&
        <>
          <div className="d-flex">
            <select className="form-select" defaultValue={range} id="Range" onChange={changeRange}>
              <option key={Range.ALL} defaultValue={Range.ALL} value={Range.ALL}>All Requests</option>
              <option key={Range.ONEWEEK} defaultValue={Range.ONEWEEK} value={Range.ONEWEEK}>From the last week</option>
              <option key={Range.TWOWEEK} defaultValue={Range.TWOWEEK} value={Range.TWOWEEK}>From the last two weeks</option>
              <option key={Range.ONEMONTH} defaultValue={Range.ONEMONTH} value={Range.ONEMONTH}>From the last month</option>
              <option key={Range.THREEMONTH} defaultValue={Range.THREEMONTH} value={Range.THREEMONTH}>From the last three months</option>
              <option key={Range.YEARTODATE} defaultValue={Range.YEARTODATE} value={Range.YEARTODATE}>From this year</option>
            </select >
          </div><br />
          {
            gridData.map((request: RequestLine) => (
              <div key={request.ID} className="card gridCard" onClick={() => {
                onCardSelection(request.ID);
              }}>

                <div className="card-body">
                  <div className="d-flex flex-md-row flex-column">
                    <div className="card-content">
                      {
                        props.showInfoColumns
                        &&
                        <h6>
                          <span>
                            <span className="badge badge-BCGBlue badge-numeric acknowledgementCount">{request.AcknowledgementCount}</span>
                            Acknowledgments
                          </span>
                        </h6>
                      }
                      <h5 className="card-title">{request.ID}/{request.ProjectName}
                        <span>
                          {request.OfflineValue === "True" &&
                            <svg className="newColor" style={{
                              float: "right"
                            }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M20.84 22.73L18.11 20H3v-1l2-2v-6c0-1.14.29-2.27.83-3.28L1.11 3l1.28-1.27l19.72 19.73zM19 15.8V11c0-3.1-2.03-5.83-5-6.71V4a2 2 0 0 0-2-2a2 2 0 0 0-2 2v.29c-.61.18-1.2.45-1.74.8zM12 23a2 2 0 0 0 2-2h-4a2 2 0 0 0 2 2" /></svg>
                          }
                        </span>
                      </h5>
                      {props.isAdministrator && <p className="card-text">{resources.dashboard.grid.partner}: {request.Partner}</p>}
                      <p className="card-text">{resources.dashboard.grid.coordinator}: {request.TransactionCoordinator}</p>
                      <p className="card-text">{resources.dashboard.grid.createdOn} {moment(request.Opened).format('DD/MM/YYYY')}</p>
                      <p className="card-text">{resources.dashboard.grid.modifiedOn} {moment(request.Modified).format('DD/MM/YYYY')}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </>
      }
    </div>

  )

};

export default RequestGrid;
