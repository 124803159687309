import { AuthState } from "../../../custom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import resources from "../../../resources.json";
import RequestGrid from "../../requestsGrid/RequestsGrid";
import React from "react";

export default function MdpView({ authContext, defaultActiveKey }: {
    defaultActiveKey: any,
    authContext: { authState: AuthState | null | undefined; } | null
}) {
    let activeTab = defaultActiveKey;
    if (defaultActiveKey === 'closed' || defaultActiveKey === 'declined') {
        activeTab = 'archived';
    }
    console.log(activeTab)
    console.log(defaultActiveKey)

    return <Tabs defaultActiveKey={activeTab} className="mb-3 tabs-mobile">
        <Tab eventKey="pending" title={resources.dashboard.pending}>
            <RequestGrid type="PENDING" showInfoColumns={false} showStatusColumns={false}
                         authContext={authContext?.authState} isAdministrator={false}></RequestGrid>
        </Tab>
        <Tab eventKey="open" title={resources.dashboard.open}>
            <RequestGrid type="OPEN" showInfoColumns={true} showStatusColumns={false}
                         authContext={authContext?.authState} isAdministrator={false}></RequestGrid>
        </Tab>
        <Tab eventKey="archived" title={resources.dashboard.archived}>
            <RequestGrid type="CLOSED" showInfoColumns={true} showStatusColumns={true}
                         authContext={authContext?.authState} isAdministrator={false}></RequestGrid>
        </Tab>
    </Tabs>;
}