import { FunctionComponent, useEffect, useState } from 'react';
import resources from '../../resources.json';
import './Feedback.css';
import { FeedbackModel } from '../../Models/FeedbackModel';
import { Modal } from 'react-bootstrap';
import httpService from '../../services/httpService';


const Feedback: FunctionComponent<FeedbackModel> = ({ ...props }) => {

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [hover, setHover] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    const [countdown, setCountdown] = useState(5);

    const sendFeedback = async () => {
         const feedback = {
            RequestID: props.RequestID === '' ? null : props.RequestID,
            Section: props.Section,
            Stars: rating,
            Comment: comment === '' ? "No comments" : comment,
        }

        await httpService.addFeedback(feedback, props.authContext.accessToken)
            .then(() => {
                setDone(true);
                setShowPopUp(false);
            })
            .catch((err: any) => {
                throw err;
            });
    };

    const onClose = () => {
        setShowPopUp(false);
        setCountdown(0);
        setComment('');
        setRating(0);
    }

    const [done, setDone] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!done)
                setShowPopUp(true);

            setCountdown(countdown - 1);
            if (comment === '' && rating === 0) {
                if (countdown === 0) {
                    setDone(true);
                    setShowPopUp(false);
                }

            }


        }, 5000);
        return () => clearTimeout(timer);
    }, [countdown, done, showPopUp]);

    return (
        <>
            {showPopUp &&
                <Modal show={showPopUp} centered>
                    <Modal.Header><h4>{resources.feedback.title}</h4><button type="button" className="close" onClick={()=> onClose()} data-dismiss="modal">X</button></Modal.Header>
                    <Modal.Body>
                        <div className="mb-8">
                            <div className="d-flex flex-column justify-content-center">
                                <label className="form-label">{resources.feedback.rating}</label>
                                <div className="star-rating">
                                    {[...Array(5)].map((star, index) => {
                                        index += 1;
                                        let key = index;
                                        return (
                                            <button type="button" key={key} className={index <= (hover || rating) ? "on" : "off"} onClick={() => {setRating(index); setIsDisabled(false);}} onMouseEnter={() => setHover(index)} onMouseLeave={() => setHover(rating)}>
                                                <span className="star">&#9733;</span>
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center">
                                <label className="form-label">{resources.feedback.comment}</label>
                                <input className='form-control' onChange={e => setComment(e.target.value)} placeholder={resources.feedback.comment} id="FeedbackComment" />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-text me-1" onClick={()=> onClose()}>Close</button>
                        <button type="button" disabled={isDisabled} onClick={() => sendFeedback()} className="btn btn-sm btn-primary">{resources.feedback.submit}</button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
};

export default Feedback;